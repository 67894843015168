import { Star, UploadSimple } from '@phosphor-icons/react';
import {
  Button,
  ConfigProvider,
  DatePicker,
  Flex,
  Form,
  Image,
  Input,
  InputNumber,
  Rate,
  Select,
  Slider,
  TimePicker,
  Upload,
  UploadFile,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Title from 'antd/es/typography/Title';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { getBase64 } from '../../../../common/utils';
import { TypewriterEffect } from '../../../../components/common/TypewriterEffect';
import { FormFieldProps, FormFieldType } from '../../form.types';

export const FormFieldConversational: React.FC<FormFieldProps> = ({
  name,
  label,
  rules,
  apiErrors,
  type,
  animatedText = false,
  inputProps = {},
  formItemProps = {},
  titleGap,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  useEffect(() => {
    setSelectedOptions([]);
  }, [label]);

  const uploadButton = (
    <Button type="primary" shape="round" size="large" icon={<UploadSimple />}>
      Upload
    </Button>
  );

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(await getBase64(file.originFileObj));
    setPreviewOpen(true);
  };

  const renderInput = () => {
    switch (type) {
      case FormFieldType.SHORT_TEXT:
      case FormFieldType.EMAIL:
        return <Input {...inputProps} />;
      case FormFieldType.LONG_TEXT:
        return (
          <div className="relative">
            <TextArea {...inputProps} />
            {inputProps?.suffix}
          </div>
        );
      case FormFieldType.TEXT_AREA:
        return <TextArea {...inputProps} />;
      case FormFieldType.NUMBER:
        return <InputNumber {...inputProps} />;
      case FormFieldType.DATE:
        return <DatePicker {...inputProps} />;
      case FormFieldType.TIME:
        return (
          <>
            <TimePicker
              getPopupContainer={() =>
                document.getElementById('time-picker-container') as HTMLElement
              }
              format="h:mm"
              // suffixIcon={<UserAddOutlined />}
              {...inputProps}
            />
            <div id="time-picker-container" />
          </>
        );
      case FormFieldType.DROPDOWN:
        return <Select {...inputProps} />;
      case FormFieldType.SELECT:
        if (inputProps?.options?.length > 0) {
          return (
            <Flex
              className="conversational-item-multi-select"
              gap={16}
              align="flex-start"
              wrap
            >
              {inputProps?.options?.length > 0 &&
                map(inputProps?.options, (item) => (
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: inputProps?.activeTheme?.textColor,
                      },
                      components: {
                        Button: {
                          borderRadius: 2,
                          colorPrimary: inputProps?.activeTheme?.textColor,
                          colorPrimaryHover: inputProps?.activeTheme?.textColor,
                          defaultColor: inputProps?.activeTheme?.textColor,
                          defaultBg: 'transparent',
                          defaultBorderColor:
                            inputProps?.activeTheme?.textColor,
                          defaultHoverBg: inputProps?.activeTheme?.textColor,
                          defaultHoverColor: inputProps?.activeTheme?.bgPrimary,
                        },
                      },
                    }}
                  >
                    <Button
                      type={
                        selectedOptions?.includes(item) ? 'primary' : 'default'
                      }
                      shape="round"
                      onClick={() => {
                        setSelectedOptions([item]);
                        inputProps.onChange([item]);
                      }}
                    >
                      {item}
                    </Button>
                  </ConfigProvider>
                ))}
            </Flex>
          );
        }
        return null;

      case FormFieldType.MULTI_SELECT:
        if (inputProps?.options?.length > 0) {
          return (
            <Flex
              className="conversational-item-multi-select"
              gap={16}
              align="center"
              wrap
            >
              {map(inputProps?.options, (item) => (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: inputProps?.activeTheme?.textColor,
                    },
                    components: {
                      Button: {
                        borderRadius: 2,
                        colorPrimary: inputProps?.activeTheme?.textColor,
                        colorPrimaryHover: inputProps?.activeTheme?.textColor,
                        defaultColor: inputProps?.activeTheme?.textColor,
                        defaultBg: 'transparent',
                        defaultBorderColor: inputProps?.activeTheme?.textColor,
                        defaultHoverBg: inputProps?.activeTheme?.textColor,
                        defaultHoverColor: inputProps?.activeTheme?.bgPrimary,
                      },
                    },
                  }}
                >
                  <Button
                    type={
                      selectedOptions?.includes(item) ? 'primary' : 'default'
                    }
                    shape="round"
                    onClick={() => {
                      let items = [];
                      if (selectedOptions?.includes(item)) {
                        items = selectedOptions.filter((i) => i !== item);
                      } else {
                        items = [...selectedOptions, item];
                      }
                      setSelectedOptions(items);
                      inputProps.onChange(items);
                    }}
                  >
                    {item}
                  </Button>
                </ConfigProvider>
              ))}
            </Flex>
          );
        }
        return null;
      case FormFieldType.FILE:
        return (
          <Flex gap={16} vertical align="flex-start">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: inputProps?.activeTheme?.textColor,
                },
                components: {
                  Button: {
                    borderRadius: 2,
                    colorPrimary: inputProps?.activeTheme?.textColor,
                    colorPrimaryHover: inputProps?.activeTheme?.textColor,
                    defaultColor: inputProps?.activeTheme?.bgPrimary,
                    defaultBg: inputProps?.activeTheme?.textColor,
                    defaultBorderColor: inputProps?.activeTheme?.textColor,
                    defaultHoverBg: inputProps?.activeTheme?.textColor,
                    defaultHoverColor: inputProps?.activeTheme?.bgPrimary,
                  },
                  Upload: {
                    actionsColor: inputProps?.activeTheme?.textColor,
                  },
                },
              }}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                onPreview={handlePreview}
                {...inputProps}
              >
                {inputProps?.fileList.length >= Number(inputProps?.maxCount)
                  ? null
                  : uploadButton}
              </Upload>
              {previewImage && (
                <Image
                  wrapperStyle={{ display: 'none' }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                      !visible && setPreviewImage(''),
                  }}
                  src={previewImage}
                />
              )}
              {inputProps?.children}
            </ConfigProvider>
          </Flex>
        );
      case FormFieldType.RATE:
        return (
          <Rate
            character={(props) => {
              const { index = 0, value = 0 } = props;
              const isSelected = index + 1 <= value;
              return (
                <Star
                  color={
                    isSelected
                      ? inputProps?.activeTheme?.textColor
                      : inputProps?.activeTheme?.textColor
                  }
                  weight="fill"
                />
              );
            }}
            {...inputProps}
          />
        );
      case FormFieldType.LINEAR_SCALE:
        return (
          <Flex
            gap={16}
            className="w-full"
            align="flex-start"
            justify="space-between"
          >
            {inputProps?.minLabel && (
              <p className="text-right text-neutrals">{inputProps?.minLabel}</p>
            )}
            <Slider {...inputProps} />
            {inputProps?.maxLabel && (
              <p className="text-neutrals">{inputProps?.maxLabel}</p>
            )}
          </Flex>
        );
      default:
    }
  };

  return (
    <>
      <Flex vertical gap={titleGap || 32}>
        <Title className="normal">
          {animatedText ? (
            <TypewriterEffect
              text={label || ''}
              speed={20}
              markdown={true}
              showRequiredMark={formItemProps?.required}
            />
          ) : (
            <Flex gap={2}>
              <Markdown className="markdown-wrapper">{label}</Markdown>
              {formItemProps?.required && (
                <span className="text-danger-on-surface ml-2">*</span>
              )}
            </Flex>
          )}
        </Title>
        <Form.Item
          name={name}
          rules={rules}
          validateStatus={apiErrors?.[name as string] ? 'error' : undefined}
          help={apiErrors?.[name as string]}
          {...formItemProps}
        >
          {renderInput()}
        </Form.Item>
      </Flex>
    </>
  );
};
