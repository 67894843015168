/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  /** Limit custom scalar type */
  Limit: { input: any; output: any };
};

export enum Accent {
  American = 'AMERICAN',
  Indian = 'INDIAN',
}

export type AddFormFieldInput = {
  prompt: Scalars['String']['input'];
};

export type AddFormFieldResponse = {
  __typename?: 'AddFormFieldResponse';
  data?: Maybe<FormStructure>;
};

export enum AiTone {
  Casual = 'CASUAL',
  Concise = 'CONCISE',
  Energetic = 'ENERGETIC',
  Professional = 'PROFESSIONAL',
  Sassy = 'SASSY',
  Witty = 'WITTY',
}

export type AnswerAndNextData = {
  formKey?: InputMaybe<Scalars['String']['input']>;
  formPreviewKey?: InputMaybe<Scalars['String']['input']>;
  submissionDuration: Scalars['Int']['input'];
  userResponse: Scalars['JSON']['input'];
};

export type AnswerAndNextResponse = {
  __typename?: 'AnswerAndNextResponse';
  data?: Maybe<SubmissionTranscript>;
};

export type ChangePasswordData = {
  __typename?: 'ChangePasswordData';
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type ChangePasswordResponse = {
  __typename?: 'ChangePasswordResponse';
  data?: Maybe<ChangePasswordData>;
  message?: Maybe<Scalars['String']['output']>;
};

export type CommonMessageResponse = {
  __typename?: 'CommonMessageResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type CreateFormInput = {
  prompt: Scalars['String']['input'];
};

export type CreateUpdateUserResponse = {
  __typename?: 'CreateUpdateUserResponse';
  message?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export enum Currency {
  Inr = 'INR',
}

export type FileDetailsInput = {
  contentLength: Scalars['Int']['input'];
  fileName: Scalars['String']['input'];
};

export type FirebaseLoginDataInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  logInType: LoginType;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
};

export type Form = {
  __typename?: 'Form';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  designs?: Maybe<Array<Maybe<FormDesign>>>;
  formSubmissionData?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isUpdated?: Maybe<Scalars['Boolean']['output']>;
  notViewedSubmissionsCount?: Maybe<Scalars['Int']['output']>;
  previewKey?: Maybe<Scalars['String']['output']>;
  prompt?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<Array<Maybe<FormSetting>>>;
  shares?: Maybe<Array<Maybe<FormShare>>>;
  status?: Maybe<FormStatus>;
  structures?: Maybe<Array<Maybe<FormStructure>>>;
  summary?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tone?: Maybe<AiTone>;
  totalSubmissionsCount?: Maybe<Scalars['Int']['output']>;
  workspaceId?: Maybe<Scalars['ID']['output']>;
};

export type FormCategoriesResponse = {
  __typename?: 'FormCategoriesResponse';
  data?: Maybe<Array<Maybe<FormCategory>>>;
};

export type FormCategoriesSiteMapResponse = {
  __typename?: 'FormCategoriesSiteMapResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<FormCategory>>>;
};

export type FormCategory = {
  __typename?: 'FormCategory';
  formTemplates?: Maybe<Array<Maybe<FormTemplate>>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export enum FormConfigStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type FormDesign = {
  __typename?: 'FormDesign';
  formId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  removeBranding?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<FormConfigStatus>;
  theme?: Maybe<FormTheme>;
  themeId?: Maybe<Scalars['ID']['output']>;
  themeMode?: Maybe<FormThemeMode>;
  themeVariation?: Maybe<FormThemeVariation>;
  themeVariationId?: Maybe<Scalars['ID']['output']>;
};

export enum FormFileStorage {
  GoogleDrive = 'GOOGLE_DRIVE',
  OneDrive = 'ONE_DRIVE',
}

export type FormFillingUserResponse = {
  __typename?: 'FormFillingUserResponse';
  value?: Maybe<Scalars['JSON']['output']>;
};

export type FormResponse = {
  __typename?: 'FormResponse';
  data?: Maybe<Form>;
  message?: Maybe<Scalars['String']['output']>;
};

export type FormSetting = {
  __typename?: 'FormSetting';
  allowClassic?: Maybe<Scalars['Boolean']['output']>;
  callRetries?: Maybe<Scalars['Int']['output']>;
  callTimeFrom?: Maybe<Scalars['String']['output']>;
  callTimeTo?: Maybe<Scalars['String']['output']>;
  closingMessage?: Maybe<Scalars['String']['output']>;
  disclaimer?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  finalPreview?: Maybe<Scalars['Boolean']['output']>;
  formId?: Maybe<Scalars['ID']['output']>;
  generativeQuestion?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  notifyAtDay?: Maybe<Scalars['Boolean']['output']>;
  notifyAtSubmission?: Maybe<Scalars['Boolean']['output']>;
  notifyOn?: Maybe<NotifyOn>;
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<FormConfigStatus>;
  storage?: Maybe<FormFileStorage>;
  submissionCount?: Maybe<Scalars['Int']['output']>;
  tone?: Maybe<AiTone>;
};

export type FormShare = {
  __typename?: 'FormShare';
  embedConfigs?: Maybe<Scalars['JSON']['output']>;
  formId?: Maybe<Scalars['ID']['output']>;
  formType?: Maybe<FormSubmissionType>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  ogDescription?: Maybe<Scalars['String']['output']>;
  ogThumbnail?: Maybe<Scalars['String']['output']>;
  ogThumbnailUrl?: Maybe<Scalars['String']['output']>;
  ogTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<FormShareType>;
};

export type FormShareCreationInput = {
  embedConfigs?: InputMaybe<Scalars['JSON']['input']>;
  ogDescription?: InputMaybe<Scalars['String']['input']>;
  ogThumbnail?: InputMaybe<Scalars['String']['input']>;
  ogTitle?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: FormSubmissionType;
};

export type FormShareResponse = {
  __typename?: 'FormShareResponse';
  data?: Maybe<FormShare>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum FormShareType {
  Embed = 'EMBED',
  Link = 'LINK',
}

export type FormShareUniqueInput = {
  formShareId: Scalars['ID']['input'];
};

export type FormShareUpdateInput = {
  embedConfigs?: InputMaybe<Scalars['JSON']['input']>;
  formType?: InputMaybe<FormSubmissionType>;
  ogDescription?: InputMaybe<Scalars['String']['input']>;
  ogThumbnail?: InputMaybe<Scalars['String']['input']>;
  ogTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type FormShareUpdateResponse = {
  __typename?: 'FormShareUpdateResponse';
  data?: Maybe<FormShare>;
  message?: Maybe<Scalars['String']['output']>;
};

export type FormSharesResponse = {
  __typename?: 'FormSharesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<FormShare>>>;
};

export enum FormStatus {
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Ended = 'ENDED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type FormStructure = {
  __typename?: 'FormStructure';
  formId?: Maybe<Scalars['ID']['output']>;
  formStructureVersion?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  overview?: Maybe<Scalars['String']['output']>;
  status?: Maybe<FormConfigStatus>;
  structure?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
};

export type FormStructureData = {
  __typename?: 'FormStructureData';
  structure?: Maybe<FormStructure>;
};

export type FormSubmission = {
  __typename?: 'FormSubmission';
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  form?: Maybe<Form>;
  formId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  settings?: Maybe<Scalars['JSON']['output']>;
  status?: Maybe<FormSubmissionStatus>;
  submittedBy?: Maybe<Scalars['ID']['output']>;
  submittedData?: Maybe<Scalars['JSON']['output']>;
  submittedUser?: Maybe<User>;
  summary?: Maybe<Scalars['String']['output']>;
  transcripts?: Maybe<Array<Maybe<SubmissionTranscript>>>;
  type?: Maybe<FormSubmissionType>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  voiceConversation?: Maybe<Array<Maybe<SubmissionTranscript>>>;
};

export type FormSubmissionMetadataInput = {
  formVisualType: FormVisualType;
  generativeQuestion: Scalars['Boolean']['input'];
};

export type FormSubmissionOverview = {
  isTest: Scalars['Boolean']['input'];
};

export type FormSubmissionOverviewData = {
  __typename?: 'FormSubmissionOverviewData';
  averageSubmissionTime?: Maybe<Scalars['Float']['output']>;
  completedSubmissions?: Maybe<Scalars['Int']['output']>;
  incompleteSubmissions?: Maybe<Scalars['Int']['output']>;
};

export type FormSubmissionOverviewResponse = {
  __typename?: 'FormSubmissionOverviewResponse';
  data?: Maybe<FormSubmissionOverviewData>;
};

export type FormSubmissionResponse = {
  __typename?: 'FormSubmissionResponse';
  data?: Maybe<FormSubmission>;
};

export enum FormSubmissionStatus {
  Completed = 'COMPLETED',
  Incomplete = 'INCOMPLETE',
}

export enum FormSubmissionType {
  Chat = 'CHAT',
  Classic = 'CLASSIC',
  Conversation = 'CONVERSATION',
  Voice = 'VOICE',
}

export type FormSubmissionUniqueInput = {
  submissionId: Scalars['ID']['input'];
};

export type FormSubmissions = {
  __typename?: 'FormSubmissions';
  formStructure?: Maybe<Scalars['JSON']['output']>;
  submissions?: Maybe<Array<Maybe<FormSubmission>>>;
};

export type FormSubmissionsByQuestionData = {
  __typename?: 'FormSubmissionsByQuestionData';
  questionResults?: Maybe<Array<Maybe<QuestionResult>>>;
  questions?: Maybe<Scalars['JSON']['output']>;
  totalSubmissions?: Maybe<Scalars['Int']['output']>;
};

export type FormSubmissionsByQuestionResponse = {
  __typename?: 'FormSubmissionsByQuestionResponse';
  data?: Maybe<FormSubmissionsByQuestionData>;
};

export type FormSubmissionsResponse = {
  __typename?: 'FormSubmissionsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<FormSubmissions>;
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  category?: Maybe<FormCategory>;
  categoryId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayConfig?: Maybe<Scalars['JSON']['output']>;
  form?: Maybe<Form>;
  id: Scalars['ID']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  weightage?: Maybe<Scalars['Int']['output']>;
};

export type FormTemplateCategoryUniqueInput = {
  formTemplateCategoryId: Scalars['String']['input'];
};

export type FormTemplateTagsResponse = {
  __typename?: 'FormTemplateTagsResponse';
  data?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type FormTemplateUniqueInput = {
  formTemplateId: Scalars['ID']['input'];
};

export type FormTemplatesResponse = {
  __typename?: 'FormTemplatesResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<FormTemplate>>>;
};

export type FormTheme = {
  __typename?: 'FormTheme';
  configs?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isUsed?: Maybe<Scalars['Boolean']['output']>;
  themeVariations?: Maybe<Array<Maybe<FormThemeVariation>>>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum FormThemeMode {
  Auto = 'AUTO',
  Dark = 'DARK',
  Light = 'LIGHT',
}

export type FormThemeVariation = {
  __typename?: 'FormThemeVariation';
  colorPalette?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isUsed?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type FormUniqueInput = {
  formId: Scalars['ID']['input'];
};

export enum FormVisualType {
  Iframe = 'IFRAME',
  Link = 'LINK',
  Popover = 'POPOVER',
  Popup = 'POPUP',
  SideTab = 'SIDE_TAB',
  Slider = 'SLIDER',
}

export type FormsResponse = {
  __typename?: 'FormsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<Form>>>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
}

export type GenerateFormResponse = {
  __typename?: 'GenerateFormResponse';
  data?: Maybe<Form>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GetFormFilesUploadSignedUrlInput = {
  files: Array<FileDetailsInput>;
  questionId: Scalars['String']['input'];
  submissionId: Scalars['ID']['input'];
};

export type GetFormOgImageUploadSignedUrlInput = {
  fileName: Scalars['String']['input'];
  formShareId: Scalars['String']['input'];
  imageType: Scalars['String']['input'];
};

export type GetFormPageImageUploadSignedUrlInput = {
  fileName: Scalars['String']['input'];
  formId: Scalars['String']['input'];
  pageType: Scalars['String']['input'];
};

export type GetFormStructureImageUrlInput = {
  imageKey: Scalars['String']['input'];
};

export type GetFormStructureImageUrlResponse = {
  __typename?: 'GetFormStructureImageUrlResponse';
  imageUrl?: Maybe<Scalars['String']['output']>;
};

export type GetFormWhereInput = {
  formKey?: InputMaybe<Scalars['String']['input']>;
  formPreviewKey?: InputMaybe<Scalars['String']['input']>;
};

export type GetProfileImageUploadSignedUrlInput = {
  fileName: Scalars['String']['input'];
};

export type GetSubmissionFilesUploadSignedUrlResponse = {
  __typename?: 'GetSubmissionFilesUploadSignedUrlResponse';
  signedUrls?: Maybe<Array<Maybe<SignedUrlResponse>>>;
};

export type GetTranscriptResponse = {
  __typename?: 'GetTranscriptResponse';
  data?: Maybe<Array<Maybe<SubmissionTranscript>>>;
};

export type InitiateVoiceFormSubmissionInput = {
  accent: Accent;
  formId: Scalars['ID']['input'];
  gender: Gender;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  tone: Scalars['String']['input'];
};

export type IsPhoneNumberInUseInput = {
  countryCode: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type IsPhoneNumberInUseResponse = {
  __typename?: 'IsPhoneNumberInUseResponse';
  isPhoneNumberInUsed?: Maybe<Scalars['Boolean']['output']>;
};

export type ListCurrentSubmissionTranscriptsFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ListFormCategoriesFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']['input']>;
  showOnWebApp?: InputMaybe<Scalars['Boolean']['input']>;
  showOnWebHomePage?: InputMaybe<Scalars['Boolean']['input']>;
  templateLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type ListFormCategoriesSort = {
  sortBy: SortOrder;
  sortOn: ListFormCategoriesSortOnField;
};

export enum ListFormCategoriesSortOnField {
  CreatedAt = 'createdAt',
  Name = 'name',
  Weightage = 'weightage',
}

export type ListFormFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ListFormShareFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ListFormShareSort = {
  sortBy: SortOrder;
  sortOn: ListFormShareSortOnField;
};

export enum ListFormShareSortOnField {
  CreatedAt = 'createdAt',
  Type = 'type',
}

export type ListFormSort = {
  sortBy: SortOrder;
  sortOn: ListFormSortOnField;
};

export enum ListFormSortOnField {
  CreatedAt = 'createdAt',
  Status = 'status',
  Title = 'title',
}

export type ListFormSubmissionsByQuestionFilter = {
  isTest: Scalars['Boolean']['input'];
};

export type ListFormSubmissionsFilter = {
  isCompleted: Scalars['Boolean']['input'];
  isTest: Scalars['Boolean']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ListFormSubmissionsSort = {
  sortBy: SortOrder;
  sortOn: ListFormSubmissionsSortOnField;
};

export enum ListFormSubmissionsSortOnField {
  CreatedAt = 'createdAt',
}

export type ListFormTemplateSort = {
  sortBy: SortOrder;
  sortOn: ListFormTemplateSortOnField;
};

export enum ListFormTemplateSortOnField {
  CreatedAt = 'createdAt',
  Name = 'name',
  Weightage = 'weightage',
}

export type ListFormTemplateTagsFilter = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']['input']>;
  showOnWebApp?: InputMaybe<Scalars['Boolean']['input']>;
  showOnWebHomePage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ListFormTemplatesFilter = {
  categorySlug?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  showOnWeb?: InputMaybe<Scalars['Boolean']['input']>;
  showOnWebApp?: InputMaybe<Scalars['Boolean']['input']>;
  showOnWebHomePage?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ListSubmissionTranscriptsFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ListSubmissionTranscriptsSort = {
  sortBy: SortOrder;
  sortOn: ListSubmissionTranscriptsSortOnField;
};

export enum ListSubmissionTranscriptsSortOnField {
  CreatedAt = 'createdAt',
}

export type ListUserResponse = {
  __typename?: 'ListUserResponse';
  count?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<Array<Maybe<User>>>;
};

export type ListUserSort = {
  sortBy: SortOrder;
  sortOn: ListUserSortOnField;
};

export enum ListUserSortOnField {
  City = 'city',
  CreatedAt = 'createdAt',
  Email = 'email',
  Roles = 'roles',
  State = 'state',
}

export type LoginAdminInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export enum LoginType {
  Google = 'GOOGLE',
  Microsoft = 'MICROSOFT',
}

export type LoginUserInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateSubscriptionPlan?: Maybe<CommonMessageResponse>;
  addFormFields?: Maybe<AddFormFieldResponse>;
  answerAndNext?: Maybe<AnswerAndNextResponse>;
  changePassword?: Maybe<ChangePasswordResponse>;
  createForm?: Maybe<GenerateFormResponse>;
  createFormShare?: Maybe<FormShareResponse>;
  deleteAccount?: Maybe<CommonMessageResponse>;
  deleteForm?: Maybe<CommonMessageResponse>;
  deleteFormShare?: Maybe<CommonMessageResponse>;
  duplicateForm?: Maybe<CommonMessageResponse>;
  firebaseLogin?: Maybe<LoginResponse>;
  forgotPassword?: Maybe<CommonMessageResponse>;
  generateFormStructure?: Maybe<FormResponse>;
  loginAdmin?: Maybe<LoginResponse>;
  loginUser?: Maybe<LoginResponse>;
  logout?: Maybe<CommonMessageResponse>;
  publishForm?: Maybe<PublishFormResponse>;
  refreshToken?: Maybe<RefreshTokenResponse>;
  resendVerificationEmail?: Maybe<CommonMessageResponse>;
  resetPassword?: Maybe<CommonMessageResponse>;
  signUp?: Maybe<CommonMessageResponse>;
  startConversationSubmission?: Maybe<StartConversationSubmissionResponse>;
  submitForm?: Maybe<SubmitFormResponse>;
  unpublishForm?: Maybe<UnpublishFormResponse>;
  updateForm?: Maybe<FormResponse>;
  updateFormDesign?: Maybe<FormResponse>;
  updateFormSetting?: Maybe<FormResponse>;
  updateFormShare?: Maybe<FormShareUpdateResponse>;
  updateFormStructure?: Maybe<FormResponse>;
  updateFormTemplate?: Maybe<CommonMessageResponse>;
  updateUserProfile?: Maybe<CreateUpdateUserResponse>;
  updateWorkspace?: Maybe<UpdateWorkspaceResponse>;
  useFormTemplate?: Maybe<UseFormTemplateResponse>;
  verifyEmail?: Maybe<LoginResponse>;
};

export type MutationActivateSubscriptionPlanArgs = {
  data: SubscriptionPlanUniqueInput;
};

export type MutationAddFormFieldsArgs = {
  data: AddFormFieldInput;
  where: FormUniqueInput;
};

export type MutationAnswerAndNextArgs = {
  data: AnswerAndNextData;
  where: TranscriptUniqueInput;
};

export type MutationChangePasswordArgs = {
  data: ChangePasswordInput;
};

export type MutationCreateFormArgs = {
  data: CreateFormInput;
};

export type MutationCreateFormShareArgs = {
  data: FormShareCreationInput;
  where: FormUniqueInput;
};

export type MutationDeleteFormArgs = {
  where: FormUniqueInput;
};

export type MutationDeleteFormShareArgs = {
  where: FormShareUniqueInput;
};

export type MutationDuplicateFormArgs = {
  where: FormUniqueInput;
};

export type MutationFirebaseLoginArgs = {
  data?: InputMaybe<FirebaseLoginDataInput>;
};

export type MutationForgotPasswordArgs = {
  data: ForgotPasswordInput;
};

export type MutationGenerateFormStructureArgs = {
  data: FormUniqueInput;
};

export type MutationLoginAdminArgs = {
  data: LoginAdminInput;
};

export type MutationLoginUserArgs = {
  data: LoginUserInput;
};

export type MutationPublishFormArgs = {
  where: FormUniqueInput;
};

export type MutationRefreshTokenArgs = {
  data: RefreshTokenInput;
};

export type MutationResendVerificationEmailArgs = {
  data: ResendVerificationEmailData;
};

export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};

export type MutationSignUpArgs = {
  data: SignUpInputData;
};

export type MutationStartConversationSubmissionArgs = {
  data: StartConversationSubmissionInput;
};

export type MutationSubmitFormArgs = {
  data: SubmitFormData;
  where: FormSubmissionUniqueInput;
};

export type MutationUnpublishFormArgs = {
  data?: InputMaybe<UnpublishFormData>;
  where: FormUniqueInput;
};

export type MutationUpdateFormArgs = {
  data: UpdateFormInput;
  where: FormUniqueInput;
};

export type MutationUpdateFormDesignArgs = {
  data: UpdateFormDesignInput;
  where: FormUniqueInput;
};

export type MutationUpdateFormSettingArgs = {
  data: UpdateFormSettingInput;
  where: FormUniqueInput;
};

export type MutationUpdateFormShareArgs = {
  data: FormShareUpdateInput;
  where: FormShareUniqueInput;
};

export type MutationUpdateFormStructureArgs = {
  data: UpdateFormStructureInput;
  where: FormUniqueInput;
};

export type MutationUpdateFormTemplateArgs = {
  data: UpdateFormTemplateDataInput;
  where: FormUniqueInput;
};

export type MutationUpdateUserProfileArgs = {
  data: UserProfileData;
};

export type MutationUpdateWorkspaceArgs = {
  data: UpdateWorkspaceInput;
  where?: InputMaybe<WorkspaceUniqueInput>;
};

export type MutationUseFormTemplateArgs = {
  data: UseTemplateUniqueSlugInput;
};

export type MutationVerifyEmailArgs = {
  data?: InputMaybe<VerifyEmailInput>;
};

export enum NotifyOn {
  Email = 'EMAIL',
  WhatsApp = 'WHATS_APP',
}

export type PublishFormResponse = {
  __typename?: 'PublishFormResponse';
  data?: Maybe<Form>;
  message?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  currentSubmissionTranscripts?: Maybe<SubmissionTranscriptsResponse>;
  currentUser?: Maybe<User>;
  dynamicMessage?: Maybe<CommonMessageResponse>;
  form?: Maybe<FormResponse>;
  formCategories?: Maybe<FormCategoriesResponse>;
  formSetting?: Maybe<FormResponse>;
  formShare?: Maybe<FormShareResponse>;
  formShares?: Maybe<FormSharesResponse>;
  formSubmission?: Maybe<FormSubmissionResponse>;
  formSubmissionOverview?: Maybe<FormSubmissionOverviewResponse>;
  formSubmissions?: Maybe<FormSubmissionsResponse>;
  formSubmissionsByQuestion?: Maybe<FormSubmissionsByQuestionResponse>;
  formTemplate?: Maybe<FormTemplate>;
  formTemplateAdmin?: Maybe<FormTemplate>;
  formTemplateTags?: Maybe<FormTemplateTagsResponse>;
  formTemplates?: Maybe<FormTemplatesResponse>;
  formTemplatesSiteMap?: Maybe<FormTemplatesResponse>;
  formThemes?: Maybe<ThemesResponse>;
  forms?: Maybe<FormsResponse>;
  getForm?: Maybe<FormResponse>;
  getFormOgImageUploadSignedUrl?: Maybe<SignedUrlResponse>;
  getFormPageImageUploadSignedUrl?: Maybe<SignedUrlResponse>;
  getFormStructurePagesImageUrl?: Maybe<GetFormStructureImageUrlResponse>;
  getProfileImageUploadSignedUrl?: Maybe<SignedUrlResponse>;
  getSubmissionFilesUploadSignedUrl?: Maybe<GetSubmissionFilesUploadSignedUrlResponse>;
  initiateVoiceFormSubmission?: Maybe<CommonMessageResponse>;
  isPhoneNumberInUse?: Maybe<IsPhoneNumberInUseResponse>;
  listUsers?: Maybe<ListUserResponse>;
  submissionTranscripts?: Maybe<SubmissionTranscriptsResponse>;
  subscriptionPlanKeyFeatures?: Maybe<SubscriptionPlansResponse>;
  subscriptionPlans?: Maybe<SubscriptionPlansResponse>;
};

export type QueryCurrentSubmissionTranscriptsArgs = {
  filter: ListCurrentSubmissionTranscriptsFilter;
  where: SubmissionUniqueInput;
};

export type QueryDynamicMessageArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFormArgs = {
  where: FormUniqueInput;
};

export type QueryFormCategoriesArgs = {
  filter?: InputMaybe<ListFormCategoriesFilter>;
  sort?: InputMaybe<Array<ListFormCategoriesSort>>;
};

export type QueryFormSettingArgs = {
  where: FormUniqueInput;
};

export type QueryFormShareArgs = {
  where: FormShareUniqueInput;
};

export type QueryFormSharesArgs = {
  filter?: InputMaybe<ListFormShareFilter>;
  sort?: InputMaybe<Array<ListFormShareSort>>;
  where: FormUniqueInput;
};

export type QueryFormSubmissionArgs = {
  where: SubmissionUniqueInput;
};

export type QueryFormSubmissionOverviewArgs = {
  filter: FormSubmissionOverview;
  where: FormUniqueInput;
};

export type QueryFormSubmissionsArgs = {
  filter: ListFormSubmissionsFilter;
  sort?: InputMaybe<Array<ListFormSubmissionsSort>>;
  where: FormUniqueInput;
};

export type QueryFormSubmissionsByQuestionArgs = {
  filter: ListFormSubmissionsByQuestionFilter;
  where: FormUniqueInput;
};

export type QueryFormTemplateArgs = {
  where: UseTemplateUniqueSlugInput;
};

export type QueryFormTemplateAdminArgs = {
  where: FormUniqueInput;
};

export type QueryFormTemplateTagsArgs = {
  filter: ListFormTemplateTagsFilter;
  sort?: InputMaybe<SortOrder>;
};

export type QueryFormTemplatesArgs = {
  filter: ListFormTemplatesFilter;
  sort?: InputMaybe<Array<ListFormTemplateSort>>;
};

export type QueryFormTemplatesSiteMapArgs = {
  filter: ListFormTemplatesFilter;
  sort?: InputMaybe<Array<ListFormTemplateSort>>;
};

export type QueryFormThemesArgs = {
  data: FormUniqueInput;
};

export type QueryFormsArgs = {
  filter?: InputMaybe<ListFormFilter>;
  sort?: InputMaybe<Array<ListFormSort>>;
};

export type QueryGetFormArgs = {
  where: GetFormWhereInput;
};

export type QueryGetFormOgImageUploadSignedUrlArgs = {
  data: GetFormOgImageUploadSignedUrlInput;
};

export type QueryGetFormPageImageUploadSignedUrlArgs = {
  data: GetFormPageImageUploadSignedUrlInput;
};

export type QueryGetFormStructurePagesImageUrlArgs = {
  data: GetFormStructureImageUrlInput;
};

export type QueryGetProfileImageUploadSignedUrlArgs = {
  data: GetProfileImageUploadSignedUrlInput;
};

export type QueryGetSubmissionFilesUploadSignedUrlArgs = {
  data: GetFormFilesUploadSignedUrlInput;
};

export type QueryInitiateVoiceFormSubmissionArgs = {
  data: InitiateVoiceFormSubmissionInput;
};

export type QueryIsPhoneNumberInUseArgs = {
  data: IsPhoneNumberInUseInput;
};

export type QueryListUsersArgs = {
  filter: QuerySearchInput;
  sort?: InputMaybe<Array<InputMaybe<ListUserSort>>>;
};

export type QuerySubmissionTranscriptsArgs = {
  filter: ListSubmissionTranscriptsFilter;
  sort?: InputMaybe<Array<ListSubmissionTranscriptsSort>>;
  where: SubmissionUniqueInput;
};

export type QuerySearchInput = {
  limit: Scalars['Int']['input'];
  roles?: InputMaybe<Array<InputMaybe<UserRole>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type QuestionResult = {
  __typename?: 'QuestionResult';
  data?: Maybe<Array<Maybe<SubmissionByQuestionData>>>;
  key?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String']['input'];
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type ResendVerificationEmailData = {
  email: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};

export type SignUpInputData = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse';
  key?: Maybe<Scalars['String']['output']>;
  signedUrl?: Maybe<Scalars['String']['output']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StartConversationSubmissionInput = {
  formKey?: InputMaybe<Scalars['String']['input']>;
  formPreviewKey?: InputMaybe<Scalars['String']['input']>;
  formType: FormSubmissionType;
  metadata: FormSubmissionMetadataInput;
};

export type StartConversationSubmissionResponse = {
  __typename?: 'StartConversationSubmissionResponse';
  data?: Maybe<SubmissionTranscript>;
};

export type StructureUniqueInput = {
  structureId: Scalars['String']['input'];
};

export type SubmissionByQuestionData = {
  __typename?: 'SubmissionByQuestionData';
  count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  names?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  value?: Maybe<Scalars['JSON']['output']>;
};

export type SubmissionTranscript = {
  __typename?: 'SubmissionTranscript';
  abstractedData?: Maybe<Scalars['JSON']['output']>;
  aiResponse?: Maybe<Scalars['String']['output']>;
  completionTokens?: Maybe<Scalars['Int']['output']>;
  configs?: Maybe<Scalars['JSON']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  options?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  promptTokens?: Maybe<Scalars['Int']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  submission?: Maybe<FormSubmission>;
  submissionId?: Maybe<Scalars['ID']['output']>;
  totalCost?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userResponse?: Maybe<FormFillingUserResponse>;
};

export type SubmissionTranscriptsResponse = {
  __typename?: 'SubmissionTranscriptsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Array<Maybe<SubmissionTranscript>>>;
};

export type SubmissionUniqueInput = {
  submissionId: Scalars['ID']['input'];
};

export type SubmitFormData = {
  formKey?: InputMaybe<Scalars['String']['input']>;
  formPreviewKey?: InputMaybe<Scalars['String']['input']>;
  formSubmissionData: Scalars['JSON']['input'];
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  submissionDuration: Scalars['Int']['input'];
};

export type SubmitFormResponse = {
  __typename?: 'SubmitFormResponse';
  data?: Maybe<SubmitFormResponseData>;
};

export type SubmitFormResponseData = {
  __typename?: 'SubmitFormResponseData';
  errors?: Maybe<Scalars['JSON']['output']>;
  isValid?: Maybe<Scalars['Boolean']['output']>;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isSubscribed?: Maybe<Scalars['Boolean']['output']>;
  keyFeatures?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  label?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  recurring?: Maybe<Scalars['Boolean']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<SubscriptionPlanType>;
};

export enum SubscriptionPlanType {
  Monthly = 'MONTHLY',
}

export type SubscriptionPlanUniqueInput = {
  subscriptionPlanId: Scalars['String']['input'];
};

export type SubscriptionPlansResponse = {
  __typename?: 'SubscriptionPlansResponse';
  data?: Maybe<Array<Maybe<SubscriptionPlan>>>;
};

export type ThemesResponse = {
  __typename?: 'ThemesResponse';
  data?: Maybe<Array<Maybe<FormTheme>>>;
};

export type TranscriptUniqueInput = {
  transcriptId: Scalars['ID']['input'];
};

export type UnpublishFormData = {
  closingMessage?: InputMaybe<Scalars['String']['input']>;
};

export type UnpublishFormResponse = {
  __typename?: 'UnpublishFormResponse';
  data?: Maybe<Form>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateFormDesignInput = {
  brandLogo?: InputMaybe<Scalars['String']['input']>;
  removeBranding?: InputMaybe<Scalars['Boolean']['input']>;
  themeId?: InputMaybe<Scalars['ID']['input']>;
  themeMode?: InputMaybe<FormThemeMode>;
  themeVariationId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateFormInput = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFormSettingInput = {
  closingMessage?: InputMaybe<Scalars['String']['input']>;
  finalPreview?: InputMaybe<Scalars['Boolean']['input']>;
  generativeQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  notifyAtDay?: InputMaybe<Scalars['Boolean']['input']>;
  notifyAtSubmission?: InputMaybe<Scalars['Boolean']['input']>;
  notifyOn?: InputMaybe<NotifyOn>;
  tone?: InputMaybe<AiTone>;
};

export type UpdateFormStructureInput = {
  overview?: InputMaybe<Scalars['String']['input']>;
  structure?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateFormTemplateDataInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayConfig?: InputMaybe<Scalars['JSON']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  weightage?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateFromStructureResponse = {
  __typename?: 'UpdateFromStructureResponse';
  data?: Maybe<FormStructureData>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UpdateWorkspaceInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkspaceResponse = {
  __typename?: 'UpdateWorkspaceResponse';
  data?: Maybe<Workspace>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UseFormTemplateResponse = {
  __typename?: 'UseFormTemplateResponse';
  data?: Maybe<Form>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UseTemplateUniqueInput = {
  formTemplateId: Scalars['ID']['input'];
};

export type UseTemplateUniqueSlugInput = {
  formTemplateSlug: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  companyName?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  defaultWorkspace?: Maybe<Workspace>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  hasForm?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isNewUser?: Maybe<Scalars['Boolean']['output']>;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<UserRole>>>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserProfileData = {
  discover?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
  User = 'USER',
}

export type VerifyEmailInput = {
  token: Scalars['String']['input'];
  uid: Scalars['ID']['input'];
};

export type Workspace = {
  __typename?: 'Workspace';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownedBy?: Maybe<User>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type WorkspaceUniqueInput = {
  workspaceId: Scalars['ID']['input'];
};

export type CreateFormMutationVariables = Exact<{
  data: CreateFormInput;
}>;

export type CreateFormMutation = {
  __typename?: 'Mutation';
  createForm?: {
    __typename?: 'GenerateFormResponse';
    data?: { __typename?: 'Form'; id?: string | null } | null;
  } | null;
};

export type AddFormFieldsMutationVariables = Exact<{
  where: FormUniqueInput;
  data: AddFormFieldInput;
}>;

export type AddFormFieldsMutation = {
  __typename?: 'Mutation';
  addFormFields?: {
    __typename?: 'AddFormFieldResponse';
    data?: {
      __typename?: 'FormStructure';
      overview?: string | null;
      structure?: Array<any | null> | null;
    } | null;
  } | null;
};

export type GenerateFormStructureMutationVariables = Exact<{
  data: FormUniqueInput;
}>;

export type GenerateFormStructureMutation = {
  __typename?: 'Mutation';
  generateFormStructure?: {
    __typename?: 'FormResponse';
    data?: {
      __typename?: 'Form';
      id?: string | null;
      status?: FormStatus | null;
      isUpdated?: boolean | null;
      previewKey?: string | null;
      structures?: Array<{
        __typename?: 'FormStructure';
        overview?: string | null;
        structure?: Array<any | null> | null;
      } | null> | null;
      designs?: Array<{
        __typename?: 'FormDesign';
        themeId?: string | null;
        themeMode?: FormThemeMode | null;
        themeVariationId?: string | null;
      } | null> | null;
      settings?: Array<{
        __typename?: 'FormSetting';
        tone?: AiTone | null;
        generativeQuestion?: boolean | null;
        finalPreview?: boolean | null;
        notifyOn?: NotifyOn | null;
        notifyAtDay?: boolean | null;
        notifyAtSubmission?: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateFormStructureMutationVariables = Exact<{
  where: FormUniqueInput;
  data: UpdateFormStructureInput;
}>;

export type UpdateFormStructureMutation = {
  __typename?: 'Mutation';
  updateFormStructure?: {
    __typename?: 'FormResponse';
    data?: {
      __typename?: 'Form';
      id?: string | null;
      status?: FormStatus | null;
      isUpdated?: boolean | null;
      previewKey?: string | null;
      structures?: Array<{
        __typename?: 'FormStructure';
        overview?: string | null;
        structure?: Array<any | null> | null;
      } | null> | null;
      designs?: Array<{
        __typename?: 'FormDesign';
        themeId?: string | null;
        themeMode?: FormThemeMode | null;
        themeVariationId?: string | null;
      } | null> | null;
      settings?: Array<{
        __typename?: 'FormSetting';
        tone?: AiTone | null;
        generativeQuestion?: boolean | null;
        finalPreview?: boolean | null;
        notifyOn?: NotifyOn | null;
        notifyAtDay?: boolean | null;
        notifyAtSubmission?: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateFormDesignMutationVariables = Exact<{
  where: FormUniqueInput;
  data: UpdateFormDesignInput;
}>;

export type UpdateFormDesignMutation = {
  __typename?: 'Mutation';
  updateFormDesign?: {
    __typename?: 'FormResponse';
    message?: string | null;
    data?: {
      __typename?: 'Form';
      previewKey?: string | null;
      status?: FormStatus | null;
      isUpdated?: boolean | null;
    } | null;
  } | null;
};

export type UpdateFormSettingMutationVariables = Exact<{
  where: FormUniqueInput;
  data: UpdateFormSettingInput;
}>;

export type UpdateFormSettingMutation = {
  __typename?: 'Mutation';
  updateFormSetting?: {
    __typename?: 'FormResponse';
    message?: string | null;
    data?: {
      __typename?: 'Form';
      previewKey?: string | null;
      status?: FormStatus | null;
      isUpdated?: boolean | null;
    } | null;
  } | null;
};

export type StartConversationSubmissionMutationVariables = Exact<{
  data: StartConversationSubmissionInput;
}>;

export type StartConversationSubmissionMutation = {
  __typename?: 'Mutation';
  startConversationSubmission?: {
    __typename?: 'StartConversationSubmissionResponse';
    data?: {
      __typename?: 'SubmissionTranscript';
      configs?: any | null;
      id?: string | null;
      aiResponse?: string | null;
      isCompleted?: boolean | null;
      submissionId?: string | null;
    } | null;
  } | null;
};

export type AnswerAndNextMutationVariables = Exact<{
  where: TranscriptUniqueInput;
  data: AnswerAndNextData;
}>;

export type AnswerAndNextMutation = {
  __typename?: 'Mutation';
  answerAndNext?: {
    __typename?: 'AnswerAndNextResponse';
    data?: {
      __typename?: 'SubmissionTranscript';
      aiResponse?: string | null;
      id?: string | null;
      configs?: any | null;
      isCompleted?: boolean | null;
      submissionId?: string | null;
    } | null;
  } | null;
};

export type SubmitFormMutationVariables = Exact<{
  where: FormSubmissionUniqueInput;
  data: SubmitFormData;
}>;

export type SubmitFormMutation = {
  __typename?: 'Mutation';
  submitForm?: {
    __typename?: 'SubmitFormResponse';
    data?: {
      __typename?: 'SubmitFormResponseData';
      isValid?: boolean | null;
      errors?: any | null;
    } | null;
  } | null;
};

export type PublishFormMutationVariables = Exact<{
  where: FormUniqueInput;
}>;

export type PublishFormMutation = {
  __typename?: 'Mutation';
  publishForm?: {
    __typename?: 'PublishFormResponse';
    message?: string | null;
    data?: {
      __typename?: 'Form';
      previewKey?: string | null;
      status?: FormStatus | null;
      isUpdated?: boolean | null;
    } | null;
  } | null;
};

export type UpdateFormMutationVariables = Exact<{
  where: FormUniqueInput;
  data: UpdateFormInput;
}>;

export type UpdateFormMutation = {
  __typename?: 'Mutation';
  updateForm?: {
    __typename?: 'FormResponse';
    message?: string | null;
    data?: { __typename?: 'Form'; title?: string | null } | null;
  } | null;
};

export type GetFormPageImageUploadSignedUrlQueryVariables = Exact<{
  data: GetFormPageImageUploadSignedUrlInput;
}>;

export type GetFormPageImageUploadSignedUrlQuery = {
  __typename?: 'Query';
  getFormPageImageUploadSignedUrl?: {
    __typename?: 'SignedUrlResponse';
    signedUrl?: string | null;
    key?: string | null;
  } | null;
};

export type GetSubmissionFilesUploadSignedUrlQueryVariables = Exact<{
  data: GetFormFilesUploadSignedUrlInput;
}>;

export type GetSubmissionFilesUploadSignedUrlQuery = {
  __typename?: 'Query';
  getSubmissionFilesUploadSignedUrl?: {
    __typename?: 'GetSubmissionFilesUploadSignedUrlResponse';
    signedUrls?: Array<{
      __typename?: 'SignedUrlResponse';
      key?: string | null;
      signedUrl?: string | null;
    } | null> | null;
  } | null;
};

export type FormQueryVariables = Exact<{
  where: FormUniqueInput;
}>;

export type FormQuery = {
  __typename?: 'Query';
  form?: {
    __typename?: 'FormResponse';
    data?: {
      __typename?: 'Form';
      title?: string | null;
      prompt?: string | null;
      id?: string | null;
      previewKey?: string | null;
      status?: FormStatus | null;
      isUpdated?: boolean | null;
      structures?: Array<{
        __typename?: 'FormStructure';
        overview?: string | null;
        structure?: Array<any | null> | null;
      } | null> | null;
      designs?: Array<{
        __typename?: 'FormDesign';
        themeId?: string | null;
        themeMode?: FormThemeMode | null;
        themeVariationId?: string | null;
      } | null> | null;
      settings?: Array<{
        __typename?: 'FormSetting';
        tone?: AiTone | null;
        generativeQuestion?: boolean | null;
        finalPreview?: boolean | null;
        notifyOn?: NotifyOn | null;
        notifyAtDay?: boolean | null;
        notifyAtSubmission?: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};

export type FormThemesQueryVariables = Exact<{
  data: FormUniqueInput;
}>;

export type FormThemesQuery = {
  __typename?: 'Query';
  formThemes?: {
    __typename?: 'ThemesResponse';
    data?: Array<{
      __typename?: 'FormTheme';
      id?: string | null;
      title?: string | null;
      configs?: any | null;
      isUsed?: boolean | null;
      type?: string | null;
      themeVariations?: Array<{
        __typename?: 'FormThemeVariation';
        id?: string | null;
        colorPalette?: any | null;
        isUsed?: boolean | null;
        title?: string | null;
        type?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetFormQueryVariables = Exact<{
  where: GetFormWhereInput;
}>;

export type GetFormQuery = {
  __typename?: 'Query';
  getForm?: {
    __typename?: 'FormResponse';
    data?: {
      __typename?: 'Form';
      title?: string | null;
      id?: string | null;
      status?: FormStatus | null;
      designs?: Array<{
        __typename?: 'FormDesign';
        id?: string | null;
        themeMode?: FormThemeMode | null;
        theme?: { __typename?: 'FormTheme'; configs?: any | null } | null;
        themeVariation?: {
          __typename?: 'FormThemeVariation';
          colorPalette?: any | null;
          type?: string | null;
        } | null;
      } | null> | null;
      structures?: Array<{
        __typename?: 'FormStructure';
        structure?: Array<any | null> | null;
      } | null> | null;
      settings?: Array<{
        __typename?: 'FormSetting';
        generativeQuestion?: boolean | null;
        closingMessage?: string | null;
      } | null> | null;
      shares?: Array<{
        __typename?: 'FormShare';
        id?: string | null;
        formId?: string | null;
        formType?: FormSubmissionType | null;
        key?: string | null;
        title?: string | null;
        embedConfigs?: any | null;
        ogTitle?: string | null;
        type?: FormShareType | null;
        ogDescription?: string | null;
        ogThumbnail?: string | null;
        ogThumbnailUrl?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetFormStructurePagesImageUrlQueryVariables = Exact<{
  data: GetFormStructureImageUrlInput;
}>;

export type GetFormStructurePagesImageUrlQuery = {
  __typename?: 'Query';
  getFormStructurePagesImageUrl?: {
    __typename?: 'GetFormStructureImageUrlResponse';
    imageUrl?: string | null;
  } | null;
};

export type FormSubmissionQueryVariables = Exact<{
  where: SubmissionUniqueInput;
}>;

export type FormSubmissionQuery = {
  __typename?: 'Query';
  formSubmission?: {
    __typename?: 'FormSubmissionResponse';
    data?: {
      __typename?: 'FormSubmission';
      isCompleted?: boolean | null;
    } | null;
  } | null;
};

export type SubmissionTranscriptsQueryVariables = Exact<{
  filter: ListSubmissionTranscriptsFilter;
  where: SubmissionUniqueInput;
  sort?: InputMaybe<
    Array<ListSubmissionTranscriptsSort> | ListSubmissionTranscriptsSort
  >;
}>;

export type SubmissionTranscriptsQuery = {
  __typename?: 'Query';
  submissionTranscripts?: {
    __typename?: 'SubmissionTranscriptsResponse';
    data?: Array<{
      __typename?: 'SubmissionTranscript';
      configs?: any | null;
      aiResponse?: string | null;
      userResponse?: {
        __typename?: 'FormFillingUserResponse';
        value?: any | null;
      } | null;
    } | null> | null;
  } | null;
};

export const CreateFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateFormInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFormMutation, CreateFormMutationVariables>;
export const AddFormFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddFormFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FormUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddFormFieldInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addFormFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overview' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'structure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddFormFieldsMutation,
  AddFormFieldsMutationVariables
>;
export const GenerateFormStructureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateFormStructure' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FormUniqueInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateFormStructure' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUpdated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previewKey' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'structures' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'overview' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'structure' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'designs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'themeId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'themeMode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'themeVariationId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tone' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'generativeQuestion',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'finalPreview' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'notifyOn' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'notifyAtDay' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'notifyAtSubmission',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateFormStructureMutation,
  GenerateFormStructureMutationVariables
>;
export const UpdateFormStructureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFormStructure' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FormUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFormStructureInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFormStructure' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUpdated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previewKey' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'structures' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'overview' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'structure' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'designs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'themeId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'themeMode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'themeVariationId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tone' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'generativeQuestion',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'finalPreview' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'notifyOn' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'notifyAtDay' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'notifyAtSubmission',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFormStructureMutation,
  UpdateFormStructureMutationVariables
>;
export const UpdateFormDesignDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFormDesign' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FormUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFormDesignInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFormDesign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previewKey' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUpdated' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFormDesignMutation,
  UpdateFormDesignMutationVariables
>;
export const UpdateFormSettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFormSetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FormUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFormSettingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFormSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previewKey' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUpdated' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFormSettingMutation,
  UpdateFormSettingMutationVariables
>;
export const StartConversationSubmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StartConversationSubmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StartConversationSubmissionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startConversationSubmission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'configs' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aiResponse' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submissionId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartConversationSubmissionMutation,
  StartConversationSubmissionMutationVariables
>;
export const AnswerAndNextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AnswerAndNext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TranscriptUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AnswerAndNextData' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answerAndNext' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aiResponse' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'configs' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'submissionId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AnswerAndNextMutation,
  AnswerAndNextMutationVariables
>;
export const SubmitFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FormSubmissionUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubmitFormData' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isValid' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubmitFormMutation, SubmitFormMutationVariables>;
export const PublishFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FormUniqueInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previewKey' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUpdated' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublishFormMutation, PublishFormMutationVariables>;
export const UpdateFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FormUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateFormInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFormMutation, UpdateFormMutationVariables>;
export const GetFormPageImageUploadSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFormPageImageUploadSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'GetFormPageImageUploadSignedUrlInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFormPageImageUploadSignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFormPageImageUploadSignedUrlQuery,
  GetFormPageImageUploadSignedUrlQueryVariables
>;
export const GetSubmissionFilesUploadSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSubmissionFilesUploadSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetFormFilesUploadSignedUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSubmissionFilesUploadSignedUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signedUrls' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSubmissionFilesUploadSignedUrlQuery,
  GetSubmissionFilesUploadSignedUrlQueryVariables
>;
export const FormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Form' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FormUniqueInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'form' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prompt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previewKey' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUpdated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'structures' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'overview' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'structure' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'designs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'themeId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'themeMode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'themeVariationId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tone' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'generativeQuestion',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'finalPreview' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'notifyOn' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'notifyAtDay' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'notifyAtSubmission',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FormQuery, FormQueryVariables>;
export const FormThemesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FormThemes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FormUniqueInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formThemes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'configs' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUsed' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'themeVariations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'colorPalette' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isUsed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FormThemesQuery, FormThemesQueryVariables>;
export const GetFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetFormWhereInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'designs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'theme' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'configs' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'themeVariation' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'colorPalette',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'themeMode' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'structures' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'structure' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'settings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'generativeQuestion',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'closingMessage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shares' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'formId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'formType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'embedConfigs' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ogTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ogDescription' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ogThumbnail' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ogThumbnailUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFormQuery, GetFormQueryVariables>;
export const GetFormStructurePagesImageUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFormStructurePagesImageUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetFormStructureImageUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFormStructurePagesImageUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFormStructurePagesImageUrlQuery,
  GetFormStructurePagesImageUrlQueryVariables
>;
export const FormSubmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FormSubmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubmissionUniqueInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isCompleted' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FormSubmissionQuery, FormSubmissionQueryVariables>;
export const SubmissionTranscriptsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SubmissionTranscripts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ListSubmissionTranscriptsFilter' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubmissionUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ListSubmissionTranscriptsSort' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionTranscripts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sort' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userResponse' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'configs' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aiResponse' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmissionTranscriptsQuery,
  SubmissionTranscriptsQueryVariables
>;
