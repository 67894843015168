import {
  Location,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

type UseRouterReturn = {
  location: Location;
  params: Params<string>;
  navigate: ReturnType<typeof useNavigate>;
};

// Custom hook for common routing operations
function useRouter(): UseRouterReturn {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  return {
    location,
    params,
    navigate,
  };
}

export default useRouter;
