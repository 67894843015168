import { isEmpty } from 'lodash';
import { useState } from 'react';
import {
  AnswerAndNextMutation,
  Form,
  FormThemeMode,
  SubmissionTranscript,
  SubmitFormMutation,
} from '../../../__generated__/graphql';
import useRouter from '../../../hooks/useRouter';
import { FormFieldType, Palette } from '../form.types';
import {
  ANSWER_AND_NEXT_WITHOUT_AI,
  ANSWER_AND_NEXT_WITH_AI,
} from '../graphql/mutations';
import { useForm } from '../hooks/useForm';
import ConversationalUIPreview from './ConversationalUIPreview';

type FormResponseType = {
  input?: SubmissionTranscript;
  onResponse: (data: SubmissionTranscript) => void;
  formDetails: Form;
  activeTheme: Palette;
  handleCTAClick: () => void;
  handleFormCompletion: () => void;
  formSubmission?: SubmissionTranscript[];
  themeMode: FormThemeMode;
  apiLoader?: boolean;
  isAiEnabled: boolean;
  responseDuration: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  answerJson?: { [key: string]: any };
};

export default function FormResponse({
  input,
  onResponse,
  formDetails,
  formSubmission = [],
  activeTheme,
  themeMode,
  apiLoader,
  answerJson,
  isAiEnabled,
  handleCTAClick,
  responseDuration,
  handleFormCompletion,
}: FormResponseType) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [transcripts, setTranscripts] = useState<any>([]);
  const { params } = useRouter();
  const formKey = params.formKey!;
  const formPreviewKey = params.formPreviewKey!;
  const [formState, setFormState] = useState('');
  const {
    form,
    loading,
    apiErrors,
    handleSubmit: handleWithAISubmit,
  } = useForm(ANSWER_AND_NEXT_WITH_AI, {
    onCompleted: (res: AnswerAndNextMutation) => {
      const response = res.answerAndNext?.data;
      if (response) {
        if (response?.isCompleted) {
          handleFormCompletion();
        } else {
          onResponse(response);
          form.resetFields();
          // setTranscripts([
          //   ...transcripts,
          //   {
          //     type: input?.configs?.answer_type,
          //     question: input?.aiResponse,
          //     value: form.getFieldValue('userResponse'),
          //   },
          // ]);
        }
      }
    },
    onError: () => {},
  });

  const { loading: submitLoading, handleSubmit: handleWithoutAISubmit } =
    useForm(ANSWER_AND_NEXT_WITHOUT_AI, {
      onCompleted: (res: SubmitFormMutation) => {
        const response = res.submitForm?.data;
        if (response) {
          if (response?.isValid || !isEmpty(response.errors)) {
            handleFormCompletion();
          } else {
            // onResponse({
            //   configs: input?.configs,
            //   submissionId: input?.submissionId,
            //   userResponse: form.getFieldValue('userResponse'),
            // });
            // setTranscripts([
            //   ...transcripts,
            //   {
            //     type: input?.configs?.answer_type,
            //     question: input?.aiResponse,
            //     value: form.getFieldValue('userResponse'),
            //   },
            // ]);
            // form.resetFields();
          }
        }
      },
      onError: () => {},
    });

  const handleOnFinish = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
  ): Promise<boolean> => {
    const valueByAnswerKey = value?.[input?.configs?.answer_store_key];
    if (isAiEnabled) {
      let payload = '';
      if (input?.configs.answer_type === FormFieldType.PHONE_NUMBER) {
        payload = `${value?.country_code}${valueByAnswerKey ?? ''}`;
      } else {
        payload = `${valueByAnswerKey ?? ''}`;
      }
      await handleWithAISubmit({
        where: {
          transcriptId: input?.id,
        },
        data: {
          submissionDuration: responseDuration,
          userResponse: !isEmpty(payload) ? payload : 'SKIP',
          formKey: formKey,
          formPreviewKey: formPreviewKey,
        },
      });
    } else {
      let payload = {};
      if (input?.configs.answer_type === FormFieldType.PHONE_NUMBER) {
        payload = {
          ...answerJson,
          [input?.configs.question_id]: {
            value: `${value?.country_code}${valueByAnswerKey ?? ''}`,
            type: input?.configs.answer_type,
          },
        };
      } else if (input?.configs.answer_type === FormFieldType.CONTACT_INFO) {
        payload = {
          ...answerJson,
          [input?.configs.question_id]: {
            type: FormFieldType.CONTACT_INFO,
            value: {
              first_name: value?.first_name || '',
              last_name: value?.last_name || '',
              email: value?.email || '',
              company_name: value?.company_name || '',
              phone: `${value?.country_code ?? ''}${value?.phone ?? ''}`,
            },
          },
        };
      } else if (input?.configs.answer_type === FormFieldType.ADDRESS) {
        payload = {
          ...answerJson,
          [input?.configs.question_id]: {
            type: FormFieldType.ADDRESS,
            value: {
              address_line_1: value?.address_line_1 || '',
              address_line_2: value?.address_line_2 || '',
              city_town: value?.city_town || '',
              state_region_province: value?.state_region_province || '',
              zip_post_code: value?.zip_post_code || '',
              country: value?.country || '',
            },
          },
        };
      } else {
        payload = {
          ...answerJson,
          [input?.configs.question_id]: {
            value: valueByAnswerKey ? valueByAnswerKey : null,
            type: input?.configs.answer_type,
          },
        };
      }
      await handleWithoutAISubmit({
        where: {
          submissionId: input?.submissionId,
        },
        data: {
          submissionDuration: responseDuration,
          formSubmissionData: {
            ...answerJson,
            ...payload,
          },
          formKey: formKey,
          formPreviewKey: formPreviewKey,
          isCompleted: input?.isCompleted,
        },
      });
      onResponse({
        configs: input?.configs,
        submissionId: input?.submissionId,
        userResponse: payload,
      });
    }
    // const payload = isArray(value?.userResponse)
    //   ? value?.userResponse
    //   : `${value?.countryCode || ''}${value?.userResponse || ''}`;
    // form.setFieldValue('userResponse', payload || 'SKIP');
    // if (isAiEnabled) {
    //   await handleWithAISubmit({
    //     where: {
    //       transcriptId: input?.id,
    //     },
    //     data: {
    //       submissionDuration: 2, // manage dynamically
    //       userResponse: payload ? payload : 'SKIP',
    //       formKey: formKey,
    //       formPreviewKey: formPreviewKey,
    //     },
    //   });
    // } else {
    //   await handleWithoutAISubmit({
    //     where: {
    //       submissionId: input?.submissionId,
    //     },
    //     data: {
    //       submissionDuration: 2, // manage dynamically
    //       formSubmissionData: {
    //         ...answerJson,
    //         [input?.configs.answer_store_key]: {
    //           value: payload ? payload : 'SKIP',
    //           type: input?.configs.answer_type,
    //         },
    //       },
    //       formKey: formKey,
    //       formPreviewKey: formPreviewKey,
    //       isCompleted: input?.isCompleted,
    //     },
    //   });
    // }
    form.resetFields();
    return true;
  };

  return (
    <section className="w-full">
      <div className="conversational-form-preview">
        {/* {input?.configs && ( */}
        <ConversationalUIPreview
          item={{
            ...input?.configs,
            question_text: input?.aiResponse,
          }}
          autoFocus
          formDetails={formDetails}
          themeMode={themeMode}
          isUpload
          branding={true}
          onFinish={async (value) => {
            return await handleOnFinish(value);
          }}
          apiLoader={apiLoader || loading || submitLoading}
          handleCTAClick={handleCTAClick}
          activeTheme={activeTheme}
          showFooterButtons
          isAiEnabled={isAiEnabled}
        />
        {/* )} */}
      </div>
    </section>
  );
}
