import { useLazyQuery } from '@apollo/client';
import {
  CalendarBlank,
  CaretDown,
  Clock,
  Microphone,
} from '@phosphor-icons/react';
import {
  Button,
  ConfigProvider,
  Flex,
  Form,
  Grid,
  Skeleton,
  Space,
  UploadProps,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import Title from 'antd/es/typography/Title';
import { Dayjs } from 'dayjs';
import { filter, isEmpty, map } from 'lodash';
import { ReactNode, useState } from 'react';
import {
  FormStatus,
  FormThemeMode,
  Form as FormType,
} from '../../../__generated__/graphql';
import { DROPDOWN_LIST, getSliderMarks } from '../../../common/constants';
import {
  commonUploadFile,
  convertFormSchemaObjToArray,
  formValidation,
  getAcceptedFileTypes,
  getDisabledDates,
  validateFileSize,
} from '../../../common/utils';
import { TypewriterEffect } from '../../../components/common/TypewriterEffect';
import useRouter from '../../../hooks/useRouter';
import MadeWithZinq from '../../branding/MadeWithZinq';
import { FormFieldConversational } from '../editor/fields/FormFieldConversational';
import PagePreview from '../editor/pages/PagePreview';
import {
  AdditionalConfigType,
  CustomFile,
  FormFieldType,
  FormSchemaType,
  InfoType,
  Palette,
} from '../form.types';
import { GET_SIGNED_URL_UPLOAD } from '../graphql/mutations';
import FormUnpublished from './pages/FormUnpublished';

type ConversationalUIRenderType = {
  item: FormSchemaType;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onFinish: (data: any) => Promise<boolean>; // using any for dynamic values
  activeTheme: Palette;
  handleCTAClick: () => void;
  apiLoader?: boolean;
  isUpload: boolean;
  showFooterButtons: boolean;
  themeMode: FormThemeMode;
  autoFocus: boolean;
  branding: boolean;
  isAiEnabled: boolean;
  formDetails: FormType;
};

export default function ConversationalUIPreview({
  item: activeItem,
  onFinish,
  activeTheme,
  handleCTAClick,
  apiLoader,
  isUpload = false,
  showFooterButtons,
  themeMode,
  autoFocus,
  branding,
  formDetails,
  isAiEnabled,
}: ConversationalUIRenderType) {
  const [form] = useForm();
  const [apiErrors] = useState<Record<string, string>>({});
  const [fileList, setFileList] = useState<CustomFile[]>([]);
  const [optionList, setOptionList] = useState<string[]>([]);
  const [imageUploading, setImageUploading] = useState<boolean>(false);
  const { params } = useRouter();
  const formKey = params.formKey!;
  const formPreviewKey = params.formPreviewKey!;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const updatedData = convertFormSchemaObjToArray([activeItem!])?.[0];
  const item = !isEmpty(updatedData.nested)
    ? {
        ...updatedData,
        is_required:
          filter(
            updatedData.nested,
            (val: FormSchemaType) => val.is_required && !val.hidden,
          ).length > 0,
      }
    : updatedData;

  const [getSignedUrlMutate, { loading }] = useLazyQuery(
    GET_SIGNED_URL_UPLOAD,
    {
      fetchPolicy: 'network-only',

      onError: () => {},
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnFinish = async (formValue: any) => {
    await onFinish(formValue);
    setFileList([]);
    setOptionList([]);
    form.resetFields();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpload = async ({ file, onSuccess, onError }: any) => {
    try {
      if (!validateFileSize(file, Number(item?.config?.max_file_size))) {
        onError(
          new Error(
            `You can upload file size upto ${item?.config?.max_file_size}MB`,
          ),
        );
        return true;
      }

      const response = await getSignedUrlMutate({
        variables: {
          data: {
            files: [
              {
                fileName: file.name,
                contentLength: file.size,
              },
            ],
            questionId: item?.question_id as string,
            submissionId: localStorage.getItem(formPreviewKey || formKey) || '',
          },
        },
      });

      const signedUrl =
        response.data?.getSubmissionFilesUploadSignedUrl?.signedUrls?.[0]
          ?.signedUrl;
      const key =
        response?.data?.getSubmissionFilesUploadSignedUrl?.signedUrls?.[0]?.key;

      if (signedUrl && key) {
        try {
          setImageUploading(true);
          const success = await commonUploadFile(signedUrl, file);
          if (success) {
            onSuccess(response, file, response);
          } else {
            onError(new Error('Error uploading file!'));
          }
        } catch (e) {
          onError(new Error('Error uploading file!'));
        } finally {
          setImageUploading(false);
        }
      } else {
        onError(new Error('Failed to get signed URL!'));
      }
    } catch (error) {
      onError(error);
    }
  };

  const uploadProps: UploadProps = {
    accept: getAcceptedFileTypes(item?.config?.file_type),
    name: '',
    multiple: Number(item?.config?.max_file_count) > 1,
    fileList,
    customRequest: (info) => {
      if (isUpload) {
        handleUpload(info);
      } else {
        if (info.onSuccess) {
          info?.onSuccess('');
        }
      }
    },
    onRemove: () => {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange(info: any) {
      let newFileList = [...info.fileList];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url =
            file.response?.data?.getSubmissionFilesUploadSignedUrl?.signedUrls?.[0]?.key;
        }
        return file;
      });
      setFileList(newFileList);
      form.setFieldValue(
        item.answer_store_key,
        map(newFileList, (file) => file?.url),
      );
    },
  };

  const FooterButtons = ({
    isSubmit = false,
    btnText = 'Next',
    isSkip = false,
    isDisabled = false,
  }: {
    btnText?: string;
    isSubmit?: boolean;
    isSkip?: boolean;
    isDisabled?: boolean;
  }) => {
    return (
      <Flex justify="space-between">
        <div>
          {!item?.is_required && isSkip && (
            <Button
              type="default"
              size="large"
              shape="round"
              disabled={loading || apiLoader || imageUploading}
              // disabled={imageUploading}
              onClick={() => {
                // form.resetFields();
                form?.submit();
              }}
            >
              Skip
            </Button>
          )}
        </div>
        <div>
          {isSubmit && (
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              shape="round"
              loading={loading || apiLoader}
              // loading={apiLoader}
              disabled={isDisabled}
            >
              {btnText}
            </Button>
          )}
        </div>
      </Flex>
    );
  };

  const renderFooterButtons = (type: FormFieldType): ReactNode => {
    switch (type) {
      case FormFieldType.CONTACT_INFO:
      case FormFieldType.ADDRESS:
        return <FooterButtons isSubmit isSkip />;
      case FormFieldType.SHORT_TEXT:
        return <FooterButtons isSubmit isSkip />;
      case FormFieldType.EMAIL:
        return <FooterButtons isSubmit isSkip />;
      case FormFieldType.FILE:
        return (
          <FooterButtons
            isSubmit
            isSkip
            isDisabled={fileList?.length === 0 || imageUploading}
          />
        );
      case FormFieldType.LONG_TEXT:
        return <FooterButtons isSubmit isSkip />;
      case FormFieldType.NUMBER:
        return <FooterButtons isSubmit isSkip />;
      case FormFieldType.PHONE_NUMBER:
        return <FooterButtons isSubmit isSkip />;
      case FormFieldType.WELCOME_PAGE:
        return null;
      case FormFieldType.END_PAGE:
        return null;
      case FormFieldType.RATE:
        return <FooterButtons isSkip />;
      case FormFieldType.LINEAR_SCALE:
        return <FooterButtons isSkip isSubmit />;
      case FormFieldType.SELECT:
        return <FooterButtons isSkip />;
      case FormFieldType.DATE:
        return <FooterButtons isSubmit isSkip />;
      case FormFieldType.TIME:
        return <FooterButtons isSubmit isSkip />;
      case FormFieldType.MULTI_SELECT:
        return (
          <FooterButtons isSubmit={!isEmpty(optionList)} isSkip btnText="Ok" />
        );

      default:
        break;
    }
  };

  const renderItemUI = (
    item: FormSchemaType,
    info: InfoType,
    additionalConfig: AdditionalConfigType,
  ): ReactNode => {
    const commonRules = item?.is_required ? [formValidation.required] : [];
    switch (item.answer_type) {
      case FormFieldType.CONTACT_INFO:
      case FormFieldType.ADDRESS:
        if (!isAiEnabled) {
          // eslint-disable-next-line no-case-declarations
          const renderDataList = filter(
            item?.nested,
            (nestedItem) => !nestedItem.hidden,
          );
          return (
            <Flex vertical gap={32}>
              <Title className="normal">
                <TypewriterEffect
                  text={item.question_text!}
                  speed={20}
                  markdown={true}
                  showRequiredMark={false}
                />
              </Title>
              <div
                className={`group-fields ${themeMode === FormThemeMode.Light ? 'light' : ''}`}
              >
                {map(renderDataList, (val: FormSchemaType, idx) => {
                  const isLastItem = idx === renderDataList.length - 1;
                  const isLastOdd = idx % 2 === 0 && isLastItem;

                  return (
                    <ConfigProvider
                      theme={{
                        components: {
                          Typography: { fontSizeHeading1: 20 },
                          Form: {
                            itemMarginBottom: 16,
                          },
                        },
                      }}
                    >
                      {!val.hidden && (
                        <div className={isLastOdd ? 'full-width ' : ''}>
                          {renderItemUI(
                            val,
                            {
                              name: val?.answer_store_key as string,
                              label:
                                val?.aiResponse ||
                                (val?.question_text as string),
                            },
                            {
                              showFooter: isLastItem,
                              allowVoice: false,
                              titleGap: 8,
                              showRequiredMark: val.is_required || false,
                            },
                          )}
                        </div>
                      )}
                    </ConfigProvider>
                  );
                })}
              </div>
            </Flex>
          );
        } else {
          return (
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.SHORT_TEXT}
              apiErrors={apiErrors}
              animatedText
              rules={[...commonRules, formValidation.whitespace]}
              titleGap={additionalConfig.titleGap}
              inputProps={{
                placeholder: 'Type here...',
                autoSize: { minRows: 1 },
                maxLength: item?.config?.character_limit,
                autoFocus: autoFocus,
                suffix: additionalConfig.allowVoice && (
                  <span className="pointer">
                    <Button type="link" size="small">
                      <Microphone
                        size={18}
                        color={activeTheme?.textColor}
                        weight="fill"
                      />
                    </Button>
                  </span>
                ),
                activeTheme: activeTheme,
              }}
              formItemProps={{
                className: 'w-full',
                required:
                  additionalConfig.showRequiredMark && item?.is_required,
              }} //using required=false to remove * mark
            />
          );
        }
      case FormFieldType.SHORT_TEXT:
        return (
          <FormFieldConversational
            name={info?.name}
            label={info?.label}
            type={FormFieldType.SHORT_TEXT}
            apiErrors={apiErrors}
            animatedText
            rules={[...commonRules, formValidation.whitespace]}
            titleGap={additionalConfig.titleGap}
            inputProps={{
              placeholder: 'Type here...',
              autoSize: { minRows: 1 },
              maxLength: item?.config?.character_limit,
              autoFocus: autoFocus,
              suffix: additionalConfig.allowVoice && (
                <span className="pointer">
                  <Button type="link" size="small">
                    <Microphone
                      size={18}
                      color={activeTheme?.textColor}
                      weight="fill"
                    />
                  </Button>
                </span>
              ),
              activeTheme: activeTheme,
            }}
            formItemProps={{
              className: 'w-full',
              required: additionalConfig.showRequiredMark
                ? item?.is_required
                  ? true
                  : false
                : false,
            }} //using required=false to remove * mark
          />
        );
      case FormFieldType.EMAIL:
        return (
          <FormFieldConversational
            name={info?.name}
            label={info?.label}
            type={FormFieldType.SHORT_TEXT}
            animatedText
            apiErrors={apiErrors}
            rules={[
              ...commonRules,
              formValidation.email,
              formValidation.whitespace,
            ]}
            titleGap={additionalConfig.titleGap}
            inputProps={{
              placeholder: 'Type here...',
              autoSize: { minRows: 1 },
              autoFocus: autoFocus,
              maxLength: item?.config?.character_limit,
              suffix: additionalConfig.allowVoice && (
                <span className="pointer">
                  <Button type="link" size="small">
                    <Microphone
                      size={18}
                      color={activeTheme?.textColor}
                      weight="fill"
                    />
                  </Button>
                </span>
              ),
              activeTheme: activeTheme,
            }}
            formItemProps={{
              className: 'w-full',
              required: additionalConfig.showRequiredMark
                ? item?.is_required
                  ? true
                  : false
                : false,
            }}
          />
        );
      case FormFieldType.FILE:
        return (
          <FormFieldConversational
            name={info?.name}
            label={info?.label}
            type={FormFieldType.FILE}
            animatedText
            apiErrors={apiErrors}
            rules={[]}
            titleGap={additionalConfig.titleGap}
            inputProps={{
              multiple: (item?.config?.max_file_count as number) > 1 ?? false,
              maxCount: item?.config?.max_file_count,
              maxSize: item?.config?.max_file_size,
              accept: getAcceptedFileTypes(item?.config?.file_type),
              activeTheme: activeTheme,
              fileList,
              ...uploadProps,
            }}
            formItemProps={{
              className: 'w-full',
              required: additionalConfig.showRequiredMark
                ? item?.is_required
                  ? true
                  : false
                : false,
            }}
          />
        );
      case FormFieldType.LONG_TEXT:
        return (
          <FormFieldConversational
            name={info?.name}
            label={info?.label}
            type={FormFieldType.LONG_TEXT}
            animatedText
            apiErrors={apiErrors}
            rules={[...commonRules, formValidation.whitespace]}
            titleGap={additionalConfig.titleGap}
            inputProps={{
              placeholder: 'Type here...',
              autoFocus: autoFocus,
              autoSize: { minRows: 1 },
              maxLength: item?.config?.character_limit,
              suffix: additionalConfig.allowVoice && (
                <span className="pointer absolute suffix-icon-textarea">
                  <Button type="link" size="small">
                    <Microphone
                      size={18}
                      color={activeTheme?.textColor}
                      weight="fill"
                    />
                  </Button>
                </span>
              ),
              activeTheme: activeTheme,
            }}
            formItemProps={{
              className: 'w-full',
              required: additionalConfig.showRequiredMark
                ? item?.is_required
                  ? true
                  : false
                : false,
            }}
          />
        );
      case FormFieldType.NUMBER:
        return (
          <FormFieldConversational
            name={info?.name}
            label={info?.label}
            type={FormFieldType.NUMBER}
            animatedText
            apiErrors={apiErrors}
            rules={commonRules}
            titleGap={additionalConfig.titleGap}
            inputProps={{
              placeholder: 'Type here...',
              autoFocus: autoFocus,
              min: item?.config?.min_number,
              max: item?.config?.max_number,
              controls: false,
              precision: 0,
              suffix: additionalConfig.allowVoice && (
                <span className="pointer absolute suffix-icon-textarea">
                  <Button type="link" size="small">
                    <Microphone
                      size={18}
                      color={activeTheme?.textColor}
                      weight="fill"
                    />
                  </Button>
                </span>
              ),
              activeTheme: activeTheme,
            }}
            formItemProps={{
              className: 'w-full',
              required: additionalConfig.showRequiredMark
                ? item?.is_required
                  ? true
                  : false
                : false,
            }}
          />
        );
      case FormFieldType.PHONE_NUMBER:
        return (
          <FormFieldConversational
            name={info?.name}
            label={info?.label}
            type={FormFieldType.NUMBER}
            animatedText
            apiErrors={apiErrors}
            rules={commonRules}
            titleGap={additionalConfig.titleGap}
            inputProps={{
              placeholder: 'Type here...',
              controls: false,
              autoFocus: autoFocus,
              precision: 0,
              suffix: additionalConfig.allowVoice && (
                <span className="pointer absolute suffix-icon-textarea">
                  <Button type="link" size="small">
                    <Microphone
                      size={18}
                      color={activeTheme?.textColor}
                      weight="fill"
                    />
                  </Button>
                </span>
              ),
              activeTheme: activeTheme,
              addonBefore: (
                <div className="phone-addon-suffix">
                  <FormFieldConversational
                    name="country_code"
                    type={FormFieldType.DROPDOWN}
                    animatedText
                    apiErrors={apiErrors}
                    rules={commonRules}
                    inputProps={{
                      placeholder: 'Select',
                      options: DROPDOWN_LIST.countryList,
                      showSearch: true,
                      activeTheme: activeTheme,
                      suffixIcon: (
                        <CaretDown size={18} color={activeTheme?.textColor} />
                      ),
                    }}
                    formItemProps={{
                      className: 'w-full mb-0',
                      required: additionalConfig.showRequiredMark
                        ? item?.is_required
                          ? true
                          : false
                        : false,
                    }}
                  />
                </div>
              ),
            }}
            formItemProps={{
              className: 'w-full',
              required: additionalConfig.showRequiredMark
                ? item?.is_required
                  ? true
                  : false
                : false,
            }}
          />
        );
      case FormFieldType.WELCOME_PAGE:
      case FormFieldType.END_PAGE:
        return (
          <PagePreview
            item={item}
            activeTheme={activeTheme}
            handleCTAClick={handleCTAClick}
          />
        );
      case FormFieldType.RATE:
        return (
          <Space direction="vertical" className="w-full">
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.RATE}
              animatedText
              apiErrors={apiErrors}
              titleGap={additionalConfig.titleGap}
              inputProps={{
                placeholder: 'Type here...',
                className: 'w-full',
                count: item?.config?.max_rating,
                onChange: (value: string) => {
                  form.setFieldValue(info?.name, value);
                  form.submit();
                },
                activeTheme: activeTheme,
              }}
              formItemProps={{
                className: 'w-full',
                required:
                  additionalConfig.showRequiredMark && item?.is_required,
              }}
            />
            {/* commenting for future use */}
            {/* {renderItemUI(
              {
                ...item,
                is_required: additionalConfig.showRequiredMark
                ? item?.is_required
                  ? true
                  : false
                : false,
                answer_type: FormFieldType.SHORT_TEXT,
              },
              {
                name: 'textResponse',
                label: '',
              },
            )} */}
          </Space>
        );
      case FormFieldType.LINEAR_SCALE:
        return (
          <Space direction="vertical" className="w-full">
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.LINEAR_SCALE}
              animatedText
              apiErrors={apiErrors}
              titleGap={additionalConfig.titleGap}
              inputProps={{
                placeholder: 'Type here...',
                min: item?.config?.min_rating,
                max: item?.config?.max_rating,
                minLabel: item?.config?.min_label,
                maxLabel: item?.config?.max_label,
                className: 'w-full',
                marks: getSliderMarks(Number(item?.config?.max_rating)),
                activeTheme: activeTheme,
                onChangeComplete: (value: number) => {
                  form.setFieldValue(info.name, value);
                },
              }}
              formItemProps={{
                className: 'w-full',
                required:
                  additionalConfig.showRequiredMark && item?.is_required,
              }}
            />
            {/* commenting for future use */}
            {/* {renderItemUI(
              {
                ...item,
                is_required: additionalConfig.showRequiredMark
                ? item?.is_required
                  ? true
                  : false
                : false,
                answer_type: FormFieldType.SHORT_TEXT,
              },
              {
                name: 'textResponse',
                label: '',
              },
            )} */}
          </Space>
        );
      case FormFieldType.SELECT:
        return (
          <Space direction="vertical" className="w-full">
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.SELECT}
              animatedText
              apiErrors={apiErrors}
              titleGap={additionalConfig.titleGap}
              inputProps={{
                placeholder: 'Type here...',
                options: item.options ?? [],
                activeTheme: activeTheme,
                onChange: (value: string[]) => {
                  form.setFieldValue(info?.name, value);
                  form.submit();
                },
              }}
              formItemProps={{
                className: 'w-full',
                required:
                  additionalConfig.showRequiredMark && item?.is_required,
              }}
            />
            {/* commenting for future use */}
            {/* {renderItemUI(
              {
                ...item,
                is_required: additionalConfig.showRequiredMark
                ? item?.is_required
                  ? true
                  : false
                : false,
                answer_type: FormFieldType.SHORT_TEXT,
              },
              {
                name: 'textResponse',
                label: '',
              },
            )} */}
          </Space>
        );
      case FormFieldType.DATE:
        return (
          <Space direction="vertical" className="w-full">
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.DATE}
              animatedText
              apiErrors={apiErrors}
              rules={commonRules}
              titleGap={additionalConfig.titleGap}
              inputProps={{
                placeholder: 'Select date',
                // onChange: () => {
                //   form.submit();
                // },
                suffixIcon: (
                  <CalendarBlank
                    size={20}
                    color={activeTheme.textColor}
                    weight="fill"
                  />
                ),
                disabledDate: (date: Dayjs) =>
                  getDisabledDates(date, {
                    allowFuture: item?.config?.allow_future_date,
                    allowPast: item?.config?.allow_past_date,
                  }),
                allowFutureDates: item?.config?.allow_future_date,
                allowPastDates: item?.config?.allow_past_date,
                activeTheme: activeTheme,
              }}
              formItemProps={{
                className: 'w-full',
                required:
                  additionalConfig.showRequiredMark && item?.is_required,
              }}
            />
            {/* commenting for future use */}
            {/* {renderItemUI(
              {
                ...item,
                is_required: additionalConfig.showRequiredMark
                ? item?.is_required
                  ? true
                  : false
                : false,
                answer_type: FormFieldType.SHORT_TEXT,
              },
              {
                name: 'textResponse',
                label: '',
              },
            )} */}
          </Space>
        );
      case FormFieldType.TIME:
        return (
          <Space direction="vertical" className="w-full">
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.TIME}
              animatedText
              apiErrors={apiErrors}
              rules={commonRules}
              titleGap={additionalConfig.titleGap}
              inputProps={{
                placeholder: 'Select time',
                suffixIcon: (
                  <Clock
                    size={20}
                    color={activeTheme.textColor}
                    weight="fill"
                  />
                ),
                onChange: (val: string, timeString: Dayjs) => {
                  form.setFieldValue(info?.name, timeString);
                },
                activeTheme: activeTheme,
              }}
              formItemProps={{
                className: 'w-full',
                required:
                  additionalConfig.showRequiredMark && item?.is_required,
              }}
            />
            {/* commenting for future use */}
            {/* {renderItemUI(
              {
                ...item,
                is_required: additionalConfig.showRequiredMark
                ? item?.is_required
                  ? true
                  : false
                : false,
                answer_type: FormFieldType.SHORT_TEXT,
              },
              {
                name: 'textResponse',
                label: '',
              },
            )} */}
          </Space>
        );
      case FormFieldType.MULTI_SELECT:
        return (
          <Space direction="vertical" className="w-full">
            <FormFieldConversational
              name={info?.name}
              label={info?.label}
              type={FormFieldType.MULTI_SELECT}
              animatedText
              apiErrors={apiErrors}
              titleGap={additionalConfig.titleGap}
              inputProps={{
                placeholder: 'Type here...',
                options: item?.options ?? [],
                activeTheme: activeTheme,
                onChange: (value: string[]) => {
                  form.setFieldValue(info?.name, value);
                  setOptionList(value);
                },
              }}
              formItemProps={{
                className: 'w-full',
                required:
                  additionalConfig.showRequiredMark && item?.is_required,
              }}
            />
            {/* commenting for future use */}
            {/* {renderItemUI(
              {
                ...item,
                is_required: additionalConfig.showRequiredMark
                ? item?.is_required
                  ? true
                  : false
                : false,
                answer_type: FormFieldType.SHORT_TEXT,
              },
              {
                name: 'textResponse',
                label: '',
              },
            )} */}
          </Space>
        );

      default:
        break;
    }
  };

  const renderPages = () => {
    if (apiLoader) {
      return <Skeleton active className="opacity-2" />;
    }

    if (
      formKey &&
      [FormStatus.Unpublished, FormStatus.Completed, FormStatus.Ended].includes(
        formDetails.status as FormStatus,
      )
    ) {
      return (
        <FormUnpublished
          themeMode={themeMode}
          activeTheme={activeTheme}
          closingMessage={formDetails.settings?.[0]?.closingMessage}
        />
      );
    }

    return (
      <Form
        form={form}
        onFinish={handleOnFinish}
        autoComplete="off"
        className="conversational-form-renderer form-controls"
        requiredMark={false}
        initialValues={{ country_code: '+91' }}
      >
        {renderItemUI(
          item,
          {
            name: item?.answer_store_key as string,
            label: item?.aiResponse || (item?.question_text as string),
          },
          {
            showFooter: showFooterButtons,
            allowVoice: true,
            titleGap: 32,
            showRequiredMark: false,
          },
        )}
        {renderFooterButtons(item.answer_type as FormFieldType)}
        {branding && <MadeWithZinq mode={themeMode} />}
      </Form>
    );
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          boxShadow: 'unset',
          fontFamily: activeTheme?.fontFamily,
          colorText: activeTheme.textColor,
          colorLink: activeTheme.textColor,
        },
        components: {
          Input: {
            lineWidth: 0,
            borderRadius: 0,
            colorBgContainer: 'transparent',
            colorText: activeTheme.textColor,
            colorError: activeTheme.textColor,
            colorErrorText: activeTheme.textColor,
            boxShadow: 'none',
            colorTextPlaceholder: activeTheme?.textColor,
          },
          Checkbox: {
            paddingContentVertical: 16,
          },
          InputNumber: {
            lineWidth: 0,
            borderRadius: 0,
            colorBgContainer: 'transparent',
            colorText: activeTheme.textColor,
            colorError: activeTheme.textColor,
            colorErrorText: activeTheme.textColor,
            colorTextPlaceholder: activeTheme?.textColor,
            activeBorderColor: 'red',
            boxShadowSecondary: 'none',
            hoverBorderColor: 'red',
          },
          Select: {
            colorText: activeTheme.textColor,
            optionActiveBg: activeTheme.bgPrimary,
            colorBgElevated: activeTheme.bgPrimary,
            optionSelectedColor: activeTheme.bgPrimary,
            optionSelectedBg: activeTheme.textColor,
            lineWidth: 10,
          },
          DatePicker: {
            colorPrimary: activeTheme.textColor,
            cellActiveWithRangeBg: 'red',
            colorBgContainer: 'transparent',
            hoverBorderColor: activeTheme.textColor,
            activeBorderColor: activeTheme.textColor,
            colorTextDescription: 'var(--neutrals)',
            colorTextPlaceholder: activeTheme.textColor,
            colorError: activeTheme.textColor,
            colorErrorText: activeTheme.textColor,
            borderRadius: 0,
            colorText: activeTheme.textColor,
            colorBorder: activeTheme.textColor,
            colorIcon: activeTheme.textColor,
            colorBgElevated: activeTheme.bgPrimary,
            colorTextLightSolid: activeTheme.bgPrimary,
            colorTextDisabled: `${activeTheme.textColor}7d`,
            cellRangeBorderColor: 'red',
          },
          Form: {
            colorError: 'var(--red)',
            labelFontSize: 32,
            labelColor: activeTheme.textColor,
            verticalLabelPadding: '0 0 32px',
            itemMarginBottom: 32,
          },
          Slider: {
            railBg: activeTheme?.textColor,
            colorBgElevated: activeTheme?.textColor,
            railHoverBg: activeTheme?.textColor,
            trackBg: activeTheme?.textColor,
            colorPrimaryBorderHover: activeTheme?.textColor,
            colorText: activeTheme?.textColor,
            colorTextDescription: activeTheme?.textColor,
            railSize: 1,
            handleSize: 16,
            handleSizeHover: 16,
          },
          Button: {
            colorPrimary: activeTheme.textColor,
            colorPrimaryHover: activeTheme.textColor,
            colorTextLightSolid: activeTheme.bgPrimary,
            defaultColor: activeTheme?.textColor,
            defaultBg: 'transparent',
            defaultBorderColor: activeTheme?.textColor,
            defaultHoverBg: activeTheme?.textColor,
            defaultHoverColor: activeTheme?.bgPrimary,
            contentFontSizeLG: screens.lg ? 16 : 14,
          },
          Skeleton: {
            colorFillContent: activeTheme?.textColor,
          },
        },
      }}
    >
      {renderPages()}
    </ConfigProvider>
  );
}
