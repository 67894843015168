import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ConfigProvider } from 'antd';
import { cloneDeep, isEmpty, lowerCase } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  Form,
  FormSetting,
  FormShare,
  FormStatus,
  FormSubmissionType,
  FormThemeMode,
  FormVisualType,
  SubmissionTranscript,
} from '../../../__generated__/graphql';
import { getThemeBackground, ROUTES } from '../../../common/constants';
import { convertFormSchemaObjToArray } from '../../../common/utils';
import MetaHeader from '../../../components/common/MetaHeader';
import useQueryParams from '../../../hooks/useQueryParams';
import useRouter from '../../../hooks/useRouter';
import { AppContextType } from '../../../types/appContext.type';
import FormBrandingPage from '../editor/pages/FormBrandingPage';
import {
  EmbedTypes,
  FormFieldType,
  FormSchemaType,
  FormThemeType,
  PageLayoutType,
  Palette,
} from '../form.types';
import { START_CONVERSATIONS_SUBMISSION } from '../graphql/mutations';
import { GET_FORM_DETAILS, GET_FORM_SUBMISSION } from '../graphql/queries';
import FormResponse from '../view/FormResponse';
import FormDraft from '../view/pages/FormDraft';

export default function Conversational() {
  const [responseData, setResponseData] = useState<SubmissionTranscript>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [answerJson, setAnswerJson] = useState<any>();

  const { getQueryParam } = useQueryParams();
  const { params, navigate } = useRouter();
  const formKey = params.formKey!;
  const formPreviewKey = params.formPreviewKey!;
  const previewType = getQueryParam('previewType') as string;
  const [formDetails, setFormDetails] = useState<Form>();
  const [formStructure, setFormStructure] = useState<FormSchemaType[]>();
  const [formShareDetails, setFormShareDetails] = useState<FormShare>();
  const [formSetting, setFormSetting] = useState<FormSetting>();
  const [formSubmission, setFormSubmission] = useState<
    SubmissionTranscript[] | null
  >([]);
  const [showBrandPage, setShowBrandPage] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [questionList, setQuestionList] = useState<string[]>([]);
  const [timer, setTimer] = useState<number>(1);
  const timerRef = useRef<number>(0);

  const {
    state: { systemThemeMode },
  } = useContext(AppContext) as AppContextType;

  const getCurrentConfig = (configList: FormSchemaType[], counter: number) => {
    const withoutPages = configList?.filter(
      (config) =>
        config.answer_type !== FormFieldType.WELCOME_PAGE &&
        config.answer_type !== FormFieldType.END_PAGE,
    );
    if (!isEmpty(withoutPages)) {
      const item = withoutPages?.[counter];
      return !isEmpty(item) ? item : null;
    }
  };

  const handleFormCompletion = (data?: Form) => {
    const endPage = (data || formDetails)?.structures?.[0]?.structure?.filter(
      (item) => item.answer_type === FormFieldType.END_PAGE,
    )?.[0];

    if (!isEmpty(endPage) && endPage?.is_display) {
      setResponseData({
        configs: endPage,
      });
    } else {
      setShowBrandPage(true);
    }
  };

  const [startConversationMutate, { loading }] = useMutation(
    START_CONVERSATIONS_SUBMISSION,
    {
      onCompleted: (res) => {
        if (res.startConversationSubmission?.data) {
          const response = res.startConversationSubmission?.data;

          if (!isEmpty(formStructure)) {
            const currentItem = getCurrentConfig(formStructure!, counter);
            const nextItem = getCurrentConfig(formStructure!, counter + 1);

            setCounter(counter + 1);
            if (counter === Number(formStructure?.length)) {
              handleFormCompletion({
                structures: [{ structure: formStructure }],
              });
            } else {
              if (!isEmpty(currentItem)) {
                setResponseData({
                  configs: currentItem,
                  aiResponse: currentItem?.question_text,
                  submissionId:
                    res.startConversationSubmission.data.submissionId,
                  isCompleted: !nextItem ? true : false,
                });
              } else {
                handleFormCompletion({
                  structures: [{ structure: formStructure }],
                });
              }
            }
          } else {
            setResponseData(response);
          }
          if (response.submissionId) {
            localStorage.setItem(
              formPreviewKey || formKey,
              response.submissionId,
            );
          }
        }
      },
      onError() {},
    },
  );

  const [getFormSubmission] = useLazyQuery(GET_FORM_SUBMISSION);

  const { loading: formDetailsLoading } = useQuery(GET_FORM_DETAILS, {
    variables: {
      where: {
        formKey,
        formPreviewKey,
      },
    },
    onCompleted: (formResponse) => {
      const status = formResponse?.getForm?.data?.status;
      if (formResponse.getForm?.data) {
        setFormDetails(formResponse.getForm?.data);
      }
      const shareList = formResponse.getForm?.data?.shares;
      const settingList = formResponse.getForm?.data?.settings;
      const isGenerativeQuestion = settingList?.[0]?.generativeQuestion;

      if (!isEmpty(shareList)) {
        setFormShareDetails(shareList?.[0] as FormShare);
      }

      if (!isEmpty(settingList)) {
        setFormSetting(settingList?.[0] as FormSetting);
      }

      const structure = formResponse.getForm?.data?.structures?.[0]?.structure;
      if (!isGenerativeQuestion) {
        const updatedData = convertFormSchemaObjToArray(
          cloneDeep(formResponse.getForm?.data?.structures?.[0]?.structure)!,
        );
        setFormStructure(updatedData as FormSchemaType[]);
      }

      const welcomePage = (structure as FormSchemaType[])?.filter(
        (item) => item.answer_type === FormFieldType.WELCOME_PAGE,
      )?.[0];

      const submissionId = localStorage.getItem(formPreviewKey || formKey);
      // if (submissionId) {
      //   getFormSubmission({
      //     variables: {
      //       where: {
      //         submissionId,
      //       },
      //     },
      //     onCompleted: (res) => {
      //       const response = res.formSubmission?.data;
      //       if (!response?.isCompleted) {
      //         startConversationMutate({
      //           variables: {
      //             data: {
      //               formKey,
      //               formPreviewKey,
      //               formType: (previewType ||
      //                 shareList?.[0]?.type) as FormSubmissionType,
      //               metadata: {
      //                 generativeQuestion: !!isGenerativeQuestion,
      //                 formVisualType: previewType
      //                   ? FormVisualType.Link
      //                   : shareList?.[0]?.embedConfigs?.type,
      //               },
      //             },
      //           },
      //         });
      //         // getFormTranscripts({
      //         //   variables: {
      //         //     where: {
      //         //       submissionId,
      //         //     },
      //         //     sort: {
      //         //       sortOn: ListSubmissionTranscriptsSortOnField.CreatedAt,
      //         //       sortBy: SortOrder.Desc,
      //         //     },
      //         //     filter: {
      //         //       limit: 5,
      //         //       skip: 0,
      //         //     },
      //         //   },
      //         //   onCompleted: (res) => {
      //         //     setFormSubmission(
      //         //       res.submissionTranscripts?.data as SubmissionTranscript[],
      //         //     );
      //         //   },
      //         // });
      //       } else {
      //         if (formResponse.getForm?.data) {
      //           handleFormCompletion(formResponse.getForm?.data);
      //         }
      //       }
      //     },
      //   });
      // } else
      {
        const isIframe =
          shareList?.[0]?.embedConfigs?.type === EmbedTypes.IFRAME;
        const shouldShowWelcomePage =
          !isEmpty(welcomePage) &&
          ((isIframe && shareList?.[0]?.embedConfigs?.isWelcomePage) ||
            (!isIframe && welcomePage?.is_display));

        if (shouldShowWelcomePage) {
          setResponseData({
            configs: { ...welcomePage },
          });
        } else {
          const shouldStartConversation =
            !formKey || status === FormStatus.Published;

          if (shouldStartConversation) {
            startConversationMutate({
              variables: {
                data: {
                  formKey,
                  formPreviewKey,
                  formType: (previewType ||
                    shareList?.[0]?.formType) as FormSubmissionType,
                  metadata: {
                    generativeQuestion: !!isGenerativeQuestion,
                    formVisualType: previewType
                      ? FormVisualType.Link
                      : shareList?.[0]?.embedConfigs?.type ||
                        FormVisualType.Link,
                  },
                },
              },
            });
          }
        }
      }
    },
    onError: () => {
      navigate(ROUTES.NOT_FOUND);
    },
  });
  // const [getFormTranscripts] = useLazyQuery(GET_FORM_TRANSCRIPTS);

  const handleOnResponse = (data: SubmissionTranscript) => {
    const mergedData = { ...responseData, ...data };
    if (mergedData?.submissionId) {
      localStorage.setItem(formPreviewKey || formKey, mergedData.submissionId);
      // getFormTranscripts({
      //   variables: {
      //     where: {
      //       submissionId: data?.submissionId,
      //     },
      //     sort: {
      //       sortOn: ListSubmissionTranscriptsSortOnField.CreatedAt,
      //       sortBy: SortOrder.Desc,
      //     },
      //     filter: {
      //       limit: 5,
      //       skip: 0,
      //     },
      //   },
      //   onCompleted: (res) => {
      //     setFormSubmission(
      //       res.submissionTranscripts?.data as SubmissionTranscript[],
      //     );
      //   },
      // });
    }
    if (formSetting?.generativeQuestion) {
      setResponseData(mergedData);
    } else {
      if (data.configs?.question_id) {
        setQuestionList([...questionList, data.configs?.question_id]);
      }
      setAnswerJson({
        ...answerJson,
        ...data.userResponse,
      });
      const currentItem = getCurrentConfig(formStructure!, counter);
      const nextItem = getCurrentConfig(formStructure!, counter + 1);
      if (isEmpty(currentItem)) {
        handleFormCompletion();
      } else {
        setCounter(counter + 1);
        setResponseData({
          ...mergedData,
          configs: currentItem,
          aiResponse: currentItem?.question_text,
          isCompleted: !nextItem ? true : false, //manage dynamically
        });
      }
    }
  };

  useEffect(() => {
    setTimer(1);
    timerRef.current = 1;

    const timeInterval = setInterval(() => {
      timerRef.current += 1;
      setTimer(timerRef.current);
    }, 1000);

    return () => {
      if (timeInterval) {
        clearInterval(timeInterval);
      }
    };
  }, []);

  // fetch form submission - prefilled
  // useEffect(() => {
  //   const submissionId = localStorage.getItem(formPreviewKey || formKey);
  //   if (submissionId) {
  //     getFormSubmission({
  //       variables: {
  //         where: {
  //           submissionId,
  //         },
  //       },
  //       onCompleted: (res) => {
  //         const response = res.formSubmission?.data;
  //         // eslint-disable-next-line no-console
  //         console.log(response);
  //         if (!response?.isCompleted) {
  //           // getFormTranscripts({
  //           //   variables: {
  //           //     where: {
  //           //       submissionId,
  //           //     },
  //           //     sort: {
  //           //       sortOn: ListSubmissionTranscriptsSortOnField.CreatedAt,
  //           //       sortBy: SortOrder.Desc,
  //           //     },
  //           //     filter: {
  //           //       limit: 5,
  //           //       skip: 0,
  //           //     },
  //           //   },
  //           //   onCompleted: (res) => {
  //           //     setFormSubmission(
  //           //       res.submissionTranscripts?.data as SubmissionTranscript[],
  //           //     );
  //           //   },
  //           // });
  //         } else {
  //           handleFormCompletion();
  //         }
  //       },
  //     });
  //   }
  // }, []);

  const mode =
    formDetails?.designs?.[0]?.themeMode === FormThemeMode.Auto
      ? systemThemeMode
      : formDetails?.designs?.[0]?.themeMode;

  const activeTheme: Palette =
    formDetails?.designs?.[0]?.themeVariation?.colorPalette[
      lowerCase(mode as FormThemeMode)
    ];

  return (
    <>
      <MetaHeader
        title={formShareDetails?.ogTitle || ''}
        description={formShareDetails?.ogDescription || ''}
        imgUrl={formShareDetails?.ogThumbnailUrl || ''}
      />
      <div
        className="conversational-view-wrapper form-controls"
        style={
          (responseData?.configs?.answer_type === FormFieldType.WELCOME_PAGE ||
            responseData?.configs?.answer_type === FormFieldType.END_PAGE) &&
          responseData?.configs?.page_layout === PageLayoutType.PAGE_FILLED &&
          responseData?.configs?.bg_image_url &&
          formDetails?.status !== FormStatus.Unpublished
            ? {
                background: `url("${responseData?.configs?.bg_image_url}")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                boxShadow: 'rgba(0, 0, 0, 0.50) 0px 0px 0px 2000px inset',
              }
            : getThemeBackground(
                formDetails?.designs?.[0]?.themeVariation
                  ?.type as FormThemeType,
                activeTheme,
              )
        }
      >
        <div className="container">
          {formDetails?.status === FormStatus.Draft && formKey ? (
            <FormDraft />
          ) : (
            <div className="form-response">
              <ConfigProvider
                theme={{
                  components: {
                    Input: {
                      lineWidth: 0,
                      borderRadius: 0,
                      colorBgContainer: 'transparent',
                      colorText: 'var(--neutrals)',
                      hoverBorderColor: 'transparent',
                      activeBorderColor: 'transparent',
                      colorError: 'var(--neutrals)',
                      colorErrorText: 'var(--neutrals)',
                      colorTextPlaceholder: 'var(--neutrals5)',
                    },
                    Form: {
                      colorError: 'var(--neutrals)',
                    },
                  },
                }}
              >
                {showBrandPage && mode && activeTheme ? (
                  <FormBrandingPage
                    themeMode={mode}
                    activeTheme={activeTheme}
                    type={
                      formDetails?.designs?.[0]?.themeVariation
                        ?.type as FormThemeType
                    }
                  />
                ) : (
                  formDetails && (
                    <FormResponse
                      input={responseData}
                      onResponse={(data) => {
                        // handle user response
                        handleOnResponse(data);
                      }}
                      responseDuration={timer}
                      apiLoader={loading}
                      activeTheme={activeTheme}
                      themeMode={mode as FormThemeMode}
                      formDetails={formDetails}
                      handleCTAClick={() => {
                        startConversationMutate({
                          variables: {
                            data: {
                              formKey,
                              formPreviewKey,
                              formType: (previewType ||
                                formShareDetails?.formType) as FormSubmissionType,
                              metadata: {
                                generativeQuestion:
                                  !!formSetting?.generativeQuestion,
                                formVisualType: previewType
                                  ? FormVisualType.Link
                                  : formShareDetails?.embedConfigs?.type ||
                                    FormVisualType.Link,
                              },
                            },
                          },
                        });
                      }}
                      handleFormCompletion={() => {
                        handleFormCompletion();
                      }}
                      isAiEnabled={formSetting?.generativeQuestion || false}
                      formSubmission={formSubmission || []}
                      answerJson={answerJson || {}}
                    />
                  )
                )}
              </ConfigProvider>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
