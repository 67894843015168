import { Button, Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { Link } from 'react-router-dom';
import Logo from '../../../../components/common/Logo';
import { ErrorStatusPageTypes } from './pages.types';

export default function FormUnpublished({
  themeMode,
  activeTheme,
  closingMessage,
}: ErrorStatusPageTypes) {
  const editorURL = process.env.REACT_APP_EDITOR_BASE_URL!;
  return (
    <Flex gap={40} vertical align="center">
      <Flex gap={8} vertical align="center">
        <Title className="mb-0 text-center">
          No Longer Accepting Responses
        </Title>
        <Paragraph className="mb-0 text-m text-center">
          {closingMessage ||
            'This form has been closed and is no longer accepting submissions. Thanks for your interest!'}
        </Paragraph>
      </Flex>
      <Flex vertical gap={20} align="center">
        <Flex align="center" gap={8}>
          <Paragraph className="mb-0 text-base text-center">
            This form was made using
          </Paragraph>
          <Logo mode={themeMode} width={80} />
        </Flex>
        <Link to={editorURL} target="_blank">
          <Button type="primary" size="large" shape="round" block>
            Make yours now!
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
}
