import { Button, ButtonProps, ConfigProvider } from 'antd';
import PremiumLogo from '../common/PremiumLogo';

export default function CommonButton({
  children,
  premium,
  ...restProps
}: ButtonProps & {
  premium?: boolean;
}) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#21282B',
        },

        components: {
          Button: {
            controlHeight: 40,
            controlHeightLG: 48,
            controlHeightSM: 32,
            contentFontSizeLG: 16,
            contentFontSize: 14,
            contentFontSizeSM: 12,
            fontWeight: 400,
            borderRadius: 50,
            borderRadiusLG: 50,
            borderRadiusSM: 50,
            paddingInline: 20,
            paddingBlock: 8,
            paddingInlineSM: 16,
            paddingBlockSM: 8,
            paddingInlineLG: 20,
            paddingBlockLG: 8,
            colorPrimaryHover: 'var(--surface-inverse-secondary)',
            defaultBg: 'var(--surface-primary)',
            colorBorder: 'transparent',
            defaultHoverBorderColor: 'transparent',
            defaultHoverBg: 'var(--surface-tertiary)',
            textHoverBg: 'var(--surface-tertiary)',
          },
        },
      }}
    >
      <Button {...restProps}>
        {children}
        {premium && <PremiumLogo />}
      </Button>
    </ConfigProvider>
  );
}
