import { CloseCircleOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import Error404 from './components/common/Error404';
import MaintenancePage from './components/common/MaintenancePage';
import FormViewLayout from './components/layouts/FormViewLayout';
import ScreenLayout from './components/layouts/ScreenLayout';
import Conversational from './modules/form/drivers/Conversational';

type FallbackProps = {
  error: Error | null;
  componentStack: string | null;
};

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }: FallbackProps) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const FORM_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PublicRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <FormViewLayout />,
          children: [
            {
              path: ROUTES.MAIN,
              element: (
                <ScreenLayout>
                  <Error404 />
                </ScreenLayout>
              ),
            },
            {
              path: ROUTES.NOT_FOUND,
              element: (
                <ScreenLayout>
                  <Error404 />
                </ScreenLayout>
              ),
            },
            {
              path: `${ROUTES.MAIN}/:formKey`,
              element: <Conversational />,
            },
            {
              path: `${ROUTES.FORM_PREVIEW}/:formPreviewKey`,
              element: <Conversational />,
            },
            {
              path: '*',
              element: <>Error</>,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes(FORM_MODULES);
  return element;
};

const RoutesWrapper = () => {
  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router>
        {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
