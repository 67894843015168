import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { Link } from 'react-router-dom';
import { ZinqIcon } from '../../../../assets/icons';
import ScreenLayout from '../../../../components/layouts/ScreenLayout';
import CommonButton from '../../../../components/primitives/CommonButton';

export default function FormDraft() {
  const editorURL = process.env.REACT_APP_EDITOR_BASE_URL!;
  return (
    <ScreenLayout>
      <Flex gap={32} vertical align="center">
        <Flex gap={8} vertical align="center">
          <Title className="mb-0 text-center font-secondary">
            Form Not Available
          </Title>
          <Paragraph className="mb-0 text-base text-center text-content-secondary">
            This form hasn’t been published yet. Please check back later or
            visit our homepage for more information.
          </Paragraph>
        </Flex>
        <Flex vertical gap={20} align="center">
          <Link to={editorURL} target="_blank">
            <CommonButton
              type="primary"
              size="large"
              shape="round"
              block
              icon={<ZinqIcon />}
            >
              Create Your Own Form!
            </CommonButton>
          </Link>
        </Flex>
      </Flex>
    </ScreenLayout>
  );
}
