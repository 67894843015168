import { Flex } from 'antd';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';

export const TypewriterEffect = ({
  text,
  speed = 50,
  markdown = false,
  showRequiredMark = false,
}: {
  text: string;
  speed: number;
  markdown?: boolean;
  showRequiredMark: boolean;
}) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setDisplayText('');
    setCurrentIndex(0);
  }, [text]);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, speed);

      return () => clearTimeout(timer);
    }
  }, [currentIndex, text, speed]);

  const previewText = `${displayText}${currentIndex < text.length ? '|' : ''}`;
  return (
    <Flex className="typewriter-effect-wrapper" gap={0}>
      {markdown ? (
        <Markdown className="markdown-wrapper">{previewText}</Markdown>
      ) : (
        previewText
      )}
      {showRequiredMark && (
        <span className="text-danger-on-surface ml-2">*</span>
      )}
    </Flex>
  );
};
