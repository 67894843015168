import { CheckCircle } from '@phosphor-icons/react';
import { ConfigProvider, Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import { lowerCase } from 'lodash';
import { Link } from 'react-router-dom';
import { FormThemeMode } from '../../../../__generated__/graphql';
import LogoDark from '../../../../components/common/LogoDark';
import LogoLight from '../../../../components/common/LogoLight';
import { FormThemeType, Palette } from '../../form.types';

type FormBrandingPageType = {
  themeMode: FormThemeMode;
  activeTheme: Palette;
  type: FormThemeType;
};
export default function FormBrandingPage({
  themeMode,
  activeTheme,
  type,
}: FormBrandingPageType) {
  const baseURL = process.env.REACT_APP_EDITOR_BASE_URL || '';
  return (
    <div className="form-branding-page h-screen w-full">
      <ConfigProvider
        theme={{
          token: {
            boxShadow: 'unset',
            fontFamily: activeTheme?.fontFamily,
            colorText: activeTheme.textColor,
            colorLink: activeTheme.textColor,
          },
        }}
      >
        <Flex vertical justify="space-between" className="h-screen">
          <Flex justify="center" className="mt-24">
            <Link to={baseURL} target="_blank">
              {themeMode === FormThemeMode.Dark ? <LogoLight /> : <LogoDark />}
            </Link>
          </Flex>
          <Flex gap={12} vertical align="center">
            <CheckCircle
              color={activeTheme.textColor}
              weight="fill"
              size={96}
            />
            <Title level={1} className="text-response">
              Thank you for your response!
            </Title>
          </Flex>
          <div className={`branding-box ${lowerCase(themeMode)}`}>
            <Flex vertical gap={20}>
              <Flex vertical gap={8}>
                <Title level={1} className="text-header font-secondary">
                  Ready to make your own form?
                </Title>
                <Title level={1} className="font-secondary text-header">
                  It's fast, fun, and free with ZINQ.
                </Title>
              </Flex>
              <Flex justify="center">
                <Link to={baseURL} target="_blank">
                  <button className="brand-btn-primary">
                    <span className="btn-text">Make yours now!</span>
                  </button>
                </Link>
              </Flex>
            </Flex>
          </div>
        </Flex>
      </ConfigProvider>
    </div>
  );
}
